<template>
  <div>
    <div class="content-top row">
      <div class="col-24 d-flex justify-content-between align-items-center">
        <div class="content-top__title-box">
          <h1 class="content-top__heading">Lista kolorów produktów</h1>
          <div class="content-top__subheading">
            {{ objects.length }}
            {{ objects.length | numeralsText("kolor", "kolory", "kolorów") }}
          </div>
        </div>
        <button class="button button--icon" @click="addObject()">
          <icon
            icon="plus"
            icon-classes="button__icon button__icon--left icon--white icon__plus--small"
          />
          <span class="button__text">Dodaj nowy kolor</span>
        </button>
      </div>
    </div>

    <div class="content row">
      <div class="col-24">
        <table class="items-table">
          <thead class="items-table__thead">
            <tr class="items-table__tr">
              <th class="items-table__th">
                ID
              </th>
              <th class="items-table__th">
                Kolor
              </th>
              <th class="items-table__th" style="min-width: 400px;">
                Nazwa
              </th>
              <th class="items-table__th">
                Skrót
              </th>
              <th class="items-table__th">
                Obrazek
              </th>
              <th class="items-table__th p-0 text-right">
                Akcje
              </th>
            </tr>
          </thead>

          <tbody class="items-table__tbody">
            <template v-for="object in objects">
              <tr class="items-table__tr" v-bind:key="object.id">
                <td class="items-table__td">
                  <div class="form-box__mobile-element">Id</div>
                  {{ object.id }}
                </td>

                <td class="items-table__td">
                  <div class="form-box__mobile-element">Kolor</div>
                  <div class="d-flex align-items-center">
                    <div
                      v-if="object.hexcode"
                      class="form-box__color-dot mr-3"
                      :style="'background-color:' + object.hexcode"
                    ></div>
                    {{ object.hexcode }}
                  </div>
                </td>

                <td class="items-table__td items-table__td--expand">
                  <div class="form-box__mobile-element">Nazwa</div>
                  {{ object.name }}
                </td>

                <td class="items-table__td items-table__td--expand">
                  <div class="form-box__mobile-element">Skrót</div>
                  {{ object.short_name }}
                </td>

                <td class="items-table__td" style="min-width: 150px;">
                  <img :src="getApiPath(object.image_path)" />
                </td>

                <td class="items-table__td items-table__td--button">
                  <div class="form-box__mobile-element">Akcje</div>
                  <div class="d-flex justify-content-end">
                    <button
                      class="button-icon"
                      @click.prevent="editObject(object)"
                    >
                      <icon icon="edit" />
                    </button>

                    <button
                      class="button-icon button-icon--ml-10"
                      @click="deleteObject(object)"
                    >
                      <icon icon="trash" />
                    </button>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Modal add/edit -->
    <modal :show="showModal" size="lg" @close="showModal = !showModal">
      <div slot="header" class="notice-modal__header--title">
        <span v-text="!isEditing ? 'Dodawanie koloru' : 'Edycja koloru'"></span>
      </div>

      <div slot="body">
        <div class="row">
          <div class="col-24">
            <div class="product-form__title">Nazwa</div>
            <input
              class="product-form__input"
              type="text"
              v-model="object.name"
              required
            />
          </div>
          <div class="col-24  mt-4">
            <div class="product-form__title">Skrót</div>
            <input
              class="product-form__input"
              type="text"
              v-model="object.short_name"
              required
            />
          </div>
          <div class="col-24 mt-4">
            <div class="product-form__title">Kolor</div>
            <div class="d-flex align-items-center">
              <sketch-color-picker v-model="colors" @input="updateColor" />
              <input
                class="product-form__input ml-3"
                type="text"
                v-model="object.hexcode"
                @input="updateColorPicker()"
              />
            </div>
          </div>
          <div class="col-24 mt-4">
            <div class="product-form__title">Obrazek</div>

            <input
              type="file"
              ref="photosInput"
              @change="onImageChange"
              :disabled="isStoring"
              style="display: none;"
            />

            <button
              v-if="!imagePath"
              class="button button--icon mb-3"
              @click="$refs.photosInput.click()"
              :disabled="isStoring"
            >
              <icon
                v-if="!isStoring"
                icon="plus"
                icon-classes="icon--white button__icon button__icon--left"
              >
              </icon>
              <div v-else class="loader button__icon button__icon--left"></div>
              <span class="button__text">Dodaj obrazek</span>
            </button>

            <div class="product-photo mb-0" v-if="imagePath">
              <img :src="imagePath" />

              <div
                class="product-photo__actions d-flex align-items-center justify-content-end"
              >
                <button
                  class="button-icon button-icon--ml-10 button-icon--bg-white"
                  :disabled="isStoring"
                  @click="$refs.photosInput.click()"
                >
                  <icon icon="edit" />
                </button>
                <button
                  class="button-icon button-icon--ml-10 button-icon--bg-white"
                  :disabled="isStoring"
                  @click="deleteImage()"
                >
                  <icon icon="trash" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div slot="footer" class="d-flex align-items-center">
        <button
          class="button button--small button--grey"
          @click="showModal = false"
          :disabled="isStoring"
        >
          Anuluj
        </button>
        <button
          class="button button--small button--ml-10"
          @click="storeObject()"
          :disabled="isStoring"
          v-text="isStoring ? 'Zapisuję...' : 'Zapisz'"
        >
          Zapisz
        </button>
      </div>
    </modal>

    <!-- Modal delete -->
    <modal
      :show="showDeleteModal"
      size="md"
      @close="showDeleteModal = !showDeleteModal"
    >
      <div slot="header" class="notice-modal__header--title">
        Usuwanie koloru
      </div>

      <div slot="body">
        <div>
          Czy na pewno chcesz usunąć kolor
          <strong>{{
            "#" + objectToDelete.id + " - " + objectToDelete.name
          }}</strong
          >?
        </div>
      </div>

      <div slot="footer" class="d-flex align-items-center">
        <button
          class="button button--small button--grey"
          @click="showDeleteModal = false"
        >
          Nie
        </button>
        <button
          class="button button--small button--ml-10"
          @click="confirmDeleteObject()"
        >
          Tak
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import axios from "axios";
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
const API_PHOTO_URL = process.env.VUE_APP_API_PHOTO_URL;
import Icon from "./../parts/Icon";
import Modal from "./../parts/Modal";
import _ from "lodash";
import { Sketch } from "vue-color";

export default {
  data() {
    return {
      objects: {},
      object: {},
      showModal: false,
      showDeleteModal: false,
      isEditing: false,
      isStoring: false,
      imagePath: false,
      objectToDelete: {},
      colors: {},
    };
  },
  components: {
    Icon,
    Modal,
    "sketch-color-picker": Sketch,
  },
  async created() {
    this.getObjects();
  },
  methods: {
    async storeObject() {
      let vm = this;
      let data = this.object;
      this.isStoring = true;

      try {
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };

        let formData = new FormData();
        if (data.id) {
          formData.append("id", data.id);
        }
        if (data.name) {
          formData.append("name", data.name);
        }
        if (data.short_name) {
          formData.append("short_name", data.short_name);
        }
        if (data.hexcode) {
          formData.append("hexcode", data.hexcode);
        }
        if (data.image_path) {
          formData.append("image_path", data.image_path);
        }
        if (data.image) {
          formData.append("image", data.image);
        }

        const response = await axios.post(
          API_BASE_URL + "/product-colors",
          formData,
          config
        );

        vm.objects = response.data;
        vm.object = {};
        vm.showModal = false;
        vm.isStoring = false;
      } catch (error) {
        vm.isStoring = false;
        vm.$root.$emit("noticesBox", error.response.data);
      }
    },
    async getObjects() {
      try {
        const response = await axios.get(API_BASE_URL + "/product-colors");
        this.objects = response.data;
      } catch (error) {
        this.$root.$emit("noticesBox", error.response.data);
      }
    },
    editObject(object) {
      this.object = _.cloneDeep(object);
      if (object.image_path) {
        this.imagePath = this.getApiPath(object.image_path);
      } else {
        this.imagePath = false;
      }

      this.showModal = true;
      this.isEditing = true;
      this.colors = object.hexcode ? object.hexcode : {};
    },
    addObject() {
      this.object = {};
      this.imagePath = false;
      this.isEditing = false;
      this.showModal = true;
    },
    async deleteObject(object) {
      this.showDeleteModal = true;
      this.objectToDelete = object;
    },
    async confirmDeleteObject() {
      this.showDeleteModal = false;
      try {
        await axios.delete(
          API_BASE_URL + "/product-colors/" + this.objectToDelete.id
        );
        this.getObjects();
      } catch (error) {
        this.$root.$emit("noticesBox", error.response.data);
      }
    },
    onImageChange(e) {
      this.object.image = e.target.files[0];
      this.imagePath = URL.createObjectURL(this.object.image);

      this.$refs.photosInput.value = "";
    },
    deleteImage() {
      this.object.image_path = "delete";
      this.imagePath = false;
    },
    getApiPath(path) {
      if (path) {
        let timestamp = new Date().getTime();

        return API_PHOTO_URL + path + "?" + timestamp;
      } else return require("../../img/default.png");
    },
    updateColor(value) {
      this.object.hexcode = value.hex;
    },
    updateColorPicker() {
      this.colors = this.object.hexcode;
    },
  },
};
</script>
