<template>
  <div>
    <div class="content-top row">
      <div class="col-24 d-flex justify-content-between align-items-center">
        <div class="content-top__title-box">
          <h1 class="content-top__heading">Lista kategorii produktów</h1>
        </div>
      </div>
    </div>

    <div class="content row" v-if="brands.length > 0">
      <div class="col-24 col-xl-8" v-for="brand in brands" :key="brand.id">
        <categories-list
          v-if="brand.categories"
          :categories="brand.categories"
          :brand="brand"
          @addCategory="addCategory"
          @editCategory="editCategory"
          @deleteCategory="deleteCategory"
        >
        </categories-list>
      </div>
    </div>
    <div v-else class="content">
      Należy najpierw dodać
      <router-link
        class="submenu__link"
        :to="{ name: 'dictionariesProductBrands' }"
        >brand</router-link
      >.
    </div>

    <!-- Modal edit -->
    <modal
      :show="showEditModal"
      size="md"
      @close="showEditModal = !showEditModal"
    >
      <div slot="header" class="notice-modal__header--title">
        Edycja kategorii {{ "#" + editingCategory.id }}
      </div>

      <div slot="body">
        <div class="form-box">
          <label class="form-box__label">
            Nazwa:
          </label>
          <input
            v-model="editingCategory.name"
            class="form-box__input"
            @keypress.enter.prevent="saveCategory()"
          />

          <label
            class="form-box__label form-box__label--mt"
            v-if="parentsSelect"
          >
            Rodzic:
          </label>
          <treeselect
            v-if="parentsSelect"
            v-model="editingCategory.parent_id"
            :multiple="false"
            :options="parentsSelect"
            :reduce="(category) => category.id"
            :normalizer="normalizerParentsSelect"
            :clearable="true"
            :default-expand-level="10"
            placeholder=""
          />
          <label class="form-box__label form-box__label--mt">
            Wybierz instalacje:
          </label>
          <treeselect
            v-model="editingCategory.installations"
            :multiple="true"
            :options="installations"
            :reduce="(installation) => installation.id"
            :normalizer="normalizer"
            :clearable="true"
            placeholder=""
          />
          <label class="form-box__label form-box__label--mt">
            Wybierz atrybuty:
          </label>
          <treeselect
            v-model="editingCategory.attributes"
            :multiple="true"
            :options="attributes"
            :reduce="(attribute) => attribute.id"
            :normalizer="normalizer"
            :clearable="true"
            placeholder=""
          />
        </div>
      </div>

      <div slot="footer" class="d-flex align-items-center">
        <button class="button button--grey" @click="showEditModal = false">
          Anuluj
        </button>
        <button
          class="button button--icon button--ml-10"
          @click="saveCategory()"
        >
          <div
            class="loader button__icon button__icon--left"
            v-if="editingCategory.isSaving"
          ></div>
          <icon
            v-else
            icon="save"
            icon-classes="button__icon button__icon--left icon--white icon__plus--small"
          />
          <span class="button__text">Zapisz kategorię</span>
        </button>
      </div>
    </modal>

    <!-- Modal add -->
    <modal :show="showAddModal" size="md" @close="showAddModal = !showAddModal">
      <div slot="header" class="notice-modal__header--title">
        <template v-if="newCategory.parent_id">
          Dodawanie podkategorii do kategorii {{ "#" + newCategory.parent_id }}
        </template>
        <template v-else>
          Dodawanie kategorii do brandu {{ "#" + newCategory.brand_id }}
        </template>
      </div>

      <div slot="body">
        <div class="form-box">
          <label class="form-box__label">
            Podaj nazwę nowej kategorii:
          </label>
          <input
            v-model="newCategory.name"
            class="form-box__input"
            @keypress.enter.prevent="storeCategory()"
          />
        </div>
      </div>

      <div slot="footer" class="d-flex align-items-center">
        <button class="button button--grey" @click="showAddModal = false">
          Anuluj
        </button>
        <button
          class="button button--icon button--ml-10"
          @click="storeCategory()"
        >
          <icon
            icon="plus"
            icon-classes="button__icon button__icon--left icon--white icon__plus--small"
          />
          <span class="button__text">Dodaj nową kategorię</span>
        </button>
      </div>
    </modal>

    <!-- Modal delete -->
    <modal
      :show="showDeleteModal"
      size="md"
      @close="showDeleteModal = !showDeleteModal"
    >
      <div slot="header" class="notice-modal__header--title">
        Usuwanie kategorii
      </div>

      <div slot="body">
        <div>
          Czy na pewno chcesz usunąć kategorię
          <strong>{{
            "#" + objectToDelete.id + " - " + objectToDelete.name
          }}</strong
          >?
        </div>
      </div>

      <div slot="footer" class="d-flex align-items-center">
        <button
          class="button button--small button--grey"
          @click="showDeleteModal = false"
        >
          Nie
        </button>
        <button
          class="button button--small button--ml-10"
          @click="confirmDeleteCategory()"
        >
          Tak
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import axios from "axios";
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
import Icon from "./../parts/Icon";
import Modal from "./../parts/Modal";
import CategoriesList from "./../parts/CategoriesList";
import Treeselect from "@riophae/vue-treeselect";

export default {
  data() {
    return {
      brands: [],
      installations: [],
      attributes: [],
      newCategory: {
        installations: [],
      },
      editingCategory: {},
      showDeleteModal: false,
      showAddModal: false,
      showEditModal: false,
      objectToDelete: {},
      parentsSelect: [],
      normalizer(node) {
        return {
          id: node.id,
          label:
            node.unit != "" && node.unit != null
              ? node.name + " [" + node.unit + "]"
              : node.name,
        };
      },
      normalizerParentsSelect(node) {
        return {
          id: node.id,
          label: "#" + node.id + " " + node.name,
          children: node.subcategories,
        };
      },
    };
  },
  async created() {
    this.getBrands();
    this.getInstallations();
    this.getAttributes();
  },
  components: {
    Icon,
    Modal,
    CategoriesList,
    Treeselect,
  },
  mounted() {
    this.$root.$on("addCategory", (brandId, categoryId) => {
      this.addCategory(brandId, categoryId);
    });
    this.$root.$on("editCategory", (category) => {
      this.editCategory(category);
    });
    this.$root.$on("deleteCategory", (category) => {
      this.deleteCategory(category);
    });
  },
  beforeDestroy() {
    this.$root.$off("addCategory");
    this.$root.$off("editCategory");
    this.$root.$off("deleteCategory");
  },
  methods: {
    async getBrands() {
      try {
        const response = await axios.get(API_BASE_URL + "/product-brands");
        this.brands = response.data;
      } catch (error) {
        this.$root.$emit("noticesBox", error.response.data);
      }
    },
    async getInstallations() {
      try {
        const response = await axios.get(
          API_BASE_URL + "/product-installations"
        );
        this.installations = response.data;
      } catch (error) {
        this.$root.$emit("noticesBox", error.response.data);
      }
    },
    async getAttributes() {
      try {
        const response = await axios.get(API_BASE_URL + "/product-attributes");
        this.attributes = response.data;
      } catch (error) {
        this.$root.$emit("noticesBox", error.response.data);
      }
    },
    async storeCategory(data = false) {
      if (!data) data = this.newCategory;
      if (!data.languageCode)
        data.languageCode = this.$store.state.language.current;

      try {
        const response = await axios.post(
          API_BASE_URL + "/product-categories",
          data
        );
        this.newCategory.name = "";
        this.brands = response.data;
        this.editingCategory.isSaving = false;
        this.$root.$emit("noticesBox", {
          type: "success",
          message: "Poprawnie zapisano kategorię.",
        });
      } catch (error) {
        this.editingCategory.isSaving = false;
        this.$root.$emit("noticesBox", error.response.data);
      }
    },
    addCategory(brandId, categoryId) {
      this.showAddModal = true;
      this.newCategory.brand_id = brandId;
      this.newCategory.parent_id = categoryId;
    },
    editCategory(object) {
      let category = object.category;
      let brand = JSON.parse(JSON.stringify(object.brand));

      // prepare installations
      let installations = [];
      if (
        typeof category.installations != "undefined" &&
        category.installations.length > 0
      ) {
        category.installations.forEach(function(attr) {
          installations.push(attr.id);
        });
      }

      // prepare attributes
      let attributes = [];
      if (
        typeof category.attributes != "undefined" &&
        category.attributes.length > 0
      ) {
        category.attributes.forEach(function(attr) {
          attributes.push(attr.id);
        });
      }

      // show edit modal
      this.parentsSelect = brand.categories;
      this.parentsSelect.forEach((item, index) => {
        if (
          typeof item.subcategories == "undefined" ||
          item.id == category.id
        ) {
          this.parentsSelect.splice(index, 1);
        } else if (
          item.subcategories.length == 0 ||
          (item.subcategories.length == 1 &&
            item.subcategories[0].id == category.id)
        ) {
          item.subcategories = undefined;
        } else {
          this.changeEmptyArrayToUndefinedRecursive(
            item.subcategories,
            category.id
          );
        }
      });

      this.editingCategory.id = category.id;
      this.editingCategory.name = category.name;
      this.editingCategory.brand_id = category.brand_id;
      this.editingCategory.parent_id = category.parent_id;
      this.editingCategory.installations = installations;
      this.editingCategory.attributes = attributes;

      this.showEditModal = true;
    },
    deleteCategory(category) {
      this.showDeleteModal = true;
      this.objectToDelete = category;
    },
    async confirmDeleteCategory() {
      this.showDeleteModal = false;
      try {
        await axios.delete(
          API_BASE_URL + "/product-categories/" + this.objectToDelete.id
        );
        this.getBrands();
      } catch (error) {
        this.$root.$emit("noticesBox", error.response.data);
      }
    },
    saveCategory() {
      this.editingCategory.isSaving = true;
      this.storeCategory(this.editingCategory);
    },
    changeEmptyArrayToUndefinedRecursive(items, categoryId) {
      let flat = [];

      items.forEach((item, index) => {
        if (item.id == categoryId) {
          items.splice(index, 1);
          item.subcategories = undefined;
        } else if (
          item.subcategories.length == 0 ||
          (item.subcategories.length == 1 &&
            item.subcategories[0].id == categoryId)
        ) {
          item.subcategories = undefined;
        } else if (
          typeof item.subcategories != "undefined" &&
          Array.isArray(item.subcategories) &&
          item.subcategories.length > 0
        ) {
          flat.push(
            ...this.changeEmptyArrayToUndefinedRecursive(
              item.subcategories,
              categoryId
            )
          );
        } else {
          item.subcategories = undefined;
          flat.push(item);
        }
      });

      return flat;
    },
  },
};
</script>
