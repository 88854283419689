import Vue from 'vue'
import App from './App.vue';
import { router } from './router';
import store from './store/index';
import interceptorsSetup from './helpers/axios-interceptor';

// Styles
import './scss/main.scss';

// Filters
import './filters/pretty-bytes';
import './filters/pretty-date';
import './filters/numerals-text';
import './filters/uppercase';
import './filters/lowercase';

// Vue config
Vue.config.productionTip = false;

// Axios interceptors setup
interceptorsSetup();

// go VUE!
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');