<template>
    <div>

        <div class="content-top row">
            <div class="col-24 d-flex justify-content-between align-items-center">
                <div class="content-top__title-box">
                    <h1 class="content-top__heading">Lista użytkowników</h1>
                    <div class="content-top__subheading">{{ objects.length }}
                        {{ objects.length | numeralsText('użytkownik', 'użytkowników', 'użytkowników') }}
                    </div>
                </div>

                <div class="content-top__search simple-search">
                    <input type="text" class="simple-search__input" @input="searchUsers()" placeholder="Wyszukaj..."
                        v-model="search">
                    <button class="simple-search__button" @click="getObjects()">
                        <icon icon="search" />
                    </button>
                </div>
            </div>
        </div>

        <div class="content row">
            <div class="col-24">
                <table class="items-table">
                    <thead class="items-table__thead">
                        <tr class="items-table__tr">
                            <th class="items-table__th">
                                <div @click.prevent="sortObjects()"
                                    class="items-table__sort"
                                    :class="{'is-active' : orderBy == 'name'}"
                                    order-by="name"
                                    order="asc">
                                        Nazwa
                                        <icon icon="sort" icon-classes="items-table__sort-icon" />
                                </div>
                            </th>
                            <th class="items-table__th">
                                Hasło
                            </th>
                            <th class="items-table__th">
                                <div @click.prevent="sortObjects()"
                                    class="items-table__sort"
                                    :class="{'is-active' : orderBy == 'email'}"
                                    order-by="email"
                                    order="asc">
                                        E-mail
                                        <icon icon="sort" icon-classes="items-table__sort-icon" />
                                </div>
                            </th>
                            <th class="items-table__th">
                                <div @click.prevent="sortObjects()"
                                    class="items-table__sort"
                                    :class="{'is-active' : orderBy == 'role'}"
                                    order-by="role"
                                    order="asc">
                                        Uprawnienia
                                        <icon icon="sort" icon-classes="items-table__sort-icon" />
                                </div>
                            </th>
                            <th class="items-table__th p-0 text-right">
                                Akcje
                            </th>
                        </tr>
                    </thead>

                    <tbody class="items-table__tbody">
                        <template v-for="object in objects">
                            <tr class="items-table__tr" v-bind:key="object.id">

                                <td class="items-table__td">
                                    <div class="form-box__mobile-element">Nazwa</div>
                                    <div v-if="editingObject.key == object.id">
                                        <input type="text" class="items-table__input"
                                            @keypress.enter.prevent="saveObject()"
                                            v-model="editingObject.name" />
                                    </div>
                                    <div v-else>
                                        {{ object.name }}
                                    </div>
                                </td>

                                <td class="items-table__td">
                                    <div class="form-box__mobile-element">Hasło</div>
                                    <div v-if="editingObject.key == object.id">
                                        <input type="text" class="items-table__input"
                                            @keypress.enter.prevent="saveObject()"
                                            v-model="editingObject.new_password" />
                                    </div>
                                    <div v-else>
                                        *********
                                    </div>
                                </td>

                                <td class="items-table__td">
                                    <div class="form-box__mobile-element">E-mail</div>
                                    <div v-if="editingObject.key == object.id">
                                        <input type="text" class="items-table__input"
                                            @keypress.enter.prevent="saveObject()"
                                            v-model="editingObject.email" />
                                    </div>
                                    <div v-else>
                                        {{ object.email }}
                                    </div>
                                </td>

                                <td class="items-table__td">
                                    <div class="form-box__mobile-element">Uprawnienia</div>
                                    <div v-if="editingObject.key == object.id">
                                        <treeselect v-model="editingObject.role" placeholder="" :multiple="false" :options="roles" :clearable="false" />
                                    </div>
                                    <div v-else>
                                        {{ roleNames[object.role] }}
                                    </div>
                                </td>

                                <td class="items-table__td pr-0">
                                    <div class="form-box__mobile-element">Akcje</div>
                                    <div class="d-flex justify-content-end" v-if="object.id != currentUser.id">
                                        <button v-if="editingObject.key == object.id" class="button-icon"
                                            @click.prevent="saveObject()">
                                            <icon icon="save" />
                                        </button>
                                        <button v-else class="button-icon" @click.prevent="editObject(object)">
                                            <icon icon="edit" />
                                        </button>

                                        <button class="button-icon button-icon--ml-10" @click="deleteObject(object)">
                                            <icon icon="trash" />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>

        <!-- Modal delete -->
        <modal :show="showModal" size="md" @close="showModal = !showModal;">
            <div slot="header" class="notice-modal__header--title">
                Usuwanie użytkownika
            </div>

            <div slot="body">
                <div>Czy na pewno chcesz usunąć użytkownika <strong>{{ objectToDelete.email }}</strong>?</div>
            </div>

            <div slot="footer" class="d-flex align-items-center">
                <button class="button button--small button--grey" @click="showModal = false">
                    Nie
                </button>
                <button class="button button--small button--ml-10" @click="confirmdeleteObject()">
                    Tak
                </button>
            </div>
        </modal>

    </div>
</template>

<script>
    import axios from 'axios';
    const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
    import Icon from './../parts/Icon';
    import Modal from './../parts/Modal';
    import _ from 'lodash';
    import Treeselect from '@riophae/vue-treeselect';

    export default {
        data() {
            return {
                objects: {},
                editingObject: {},
                showModal: false,
                objectToDelete: false,
                search: '',
                order: 'asc',
                orderBy: 'name',
                roles: [{
                    id: 'admin',
                    label: 'Administrator'
                }, {
                    id: 'user',
                    label: 'Użytkownik'
                }],
                roleNames: {
                    'admin': 'Administrator',
                    'user': 'Użytkownik'
                }
            }
        },
        components: {
            Icon,
            Modal,
            Treeselect
        },
        async created() {
            this.getObjects();
        },
        computed: {
            currentUser() {
                return this.$store.state.auth.user;
            }
        },
        methods: {
            async storeObject(data) {
                try {
                    const response = await axios.post(API_BASE_URL + '/users', data);
                    this.objects = response.data.users;
                    this.newObject = {};
                    this.editingObject = {};

                    this.$root.$emit('noticesBox', {
                        type: 'success',
                        message: response.data.message
                    });

                } catch (error) {
                    this.$root.$emit('noticesBox', error.response.data);
                }
            },
            async getObjects() {
                try {
                    var params = {
                        'orderBy': this.orderBy,
                        'order': this.order
                    };

                    if (this.search != '') params.search = this.search;

                    const response = await axios.get(API_BASE_URL + '/users', {
                        params: params
                    });

                    this.objects = response.data;
                } catch (error) {
                    this.$root.$emit('noticesBox', error.response.data);
                }
            },
            async deleteObject(object) {
                this.showModal = true;
                this.objectToDelete = object;
            },
            async confirmdeleteObject() {
                this.showModal = false;
                try {
                    await axios.delete(API_BASE_URL + '/users/' + this.objectToDelete.id);
                    this.getObjects();
                } catch (error) {
                    this.$root.$emit('noticesBox', error.response.data);
                }
            },
            editObject(object) {
                this.editingObject = object;
                this.editingObject.key = object.id;
            },
            sortObjects() {
                event.target.setAttribute('order', ((this.order == 'asc') ? 'desc' : 'asc'));
                this.orderBy = event.target.getAttribute('order-by');
                this.order = event.target.getAttribute('order');

                this.getObjects();
            },
            async saveObject() {
                this.storeObject(this.editingObject);
            },
            searchUsers: _.debounce(function () {
                this.getObjects()
            }, 400)
        }
    }
</script>