<template>
  <div class="products-list">
    <div class="content-top row">
      <div class="col-24 content-top__wrap">
        <div class="content-top__title-box">
          <h1 class="content-top__heading">Lista produktów</h1>
          <div class="content-top__subheading">
            {{ products.total }}
            {{
              products.total | numeralsText("produkt", "produkty", "produktów")
            }}
          </div>
        </div>

        <div class="content-top__search simple-search">
          <input
            type="text"
            class="simple-search__input"
            @input="searchProducts()"
            placeholder="Wyszukaj..."
            v-model="search"
          />
          <button class="simple-search__button" @click="getProducts()">
            <icon icon="search" />
          </button>
        </div>
      </div>

      <div
        v-if="products.data && products.data.length > 0"
        class="col-24 d-flex content-top__icons-wrap"
      >
        <button
          class="button-icon button-icon--medium"
          @click.prevent="toggleStatus()"
          :disabled="selectedProducts < 1"
        >
          <icon icon="on" icon-classes="icon--regular" />
        </button>

        <div
          v-if="false"
          class="button-icon button-icon--medium button-icon--ml-10"
          @click.prevent="exportSelected()"
        >
          <icon
            v-if="!isExporting"
            icon="export"
            icon-classes="icon--regular"
          />
          <div v-else class="loader loader--icon"></div>
        </div>
      </div>
    </div>

    <div
      v-if="products.data && products.data.length > 0"
      class="content content--products-list"
    >
      <table class="items-table">
        <thead class="items-table__thead">
          <tr class="items-table__tr">
            <th class="items-table__th">
              <label for="check-product-toggle" class="checkbox">
                <input
                  id="check-product-toggle"
                  class="checkbox__input"
                  type="checkbox"
                  v-model="toggleSelectProducts"
                />
                <span class="checkbox__checkmark"></span>
              </label>
            </th>
            <th class="items-table__th">
              <div
                @click.prevent="sortObjects()"
                class="items-table__sort"
                :class="{ 'is-active': orderBy == 'index' }"
                order-by="index"
                order="desc"
              >
                Index
                <icon icon="sort" icon-classes="items-table__sort-icon" />
              </div>
            </th>
            <th class="items-table__th">Aktywny</th>
            <th class="items-table__th">
              <div
                @click.prevent="sortObjects()"
                class="items-table__sort"
                :class="{ 'is-active': orderBy == 'name' }"
                order-by="name"
                order="desc"
              >
                Nazwa
                <icon icon="sort" icon-classes="items-table__sort-icon" />
              </div>
            </th>
            <th class="items-table__th">Status synch.</th>
            <th class="items-table__th">
              <div
                @click.prevent="sortObjects()"
                class="items-table__sort"
                :class="{ 'is-active': orderBy == 'order' }"
                order-by="order"
                order="desc"
              >
                Kolejność
                <icon icon="sort" icon-classes="items-table__sort-icon" />
              </div>
            </th>

            <th class="items-table__th">
              <div
                @click.prevent="sortObjects()"
                class="items-table__sort"
                :class="{ 'is-active': orderBy == 'updated_at' }"
                order-by="updated_at"
                order="desc"
              >
                Modyfikacja
                <icon icon="sort" icon-classes="items-table__sort-icon" />
              </div>
            </th>
            <th class="items-table__th">
              Język
            </th>
            <th class="items-table__th p-0 text-right">Akcje</th>
          </tr>
        </thead>

        <tbody class="items-table__tbody">
          <!-- item -->
          <template v-for="product in products.data">
            <tr class="items-table__tr" v-bind:key="product.id">
              <td
                class="items-table__td items-table__td--not-text d-none d-xl-table-cell"
              >
                <label :for="'check-product-' + product.id" class="checkbox">
                  <input
                    :id="'check-product-' + product.id"
                    class="checkbox__input"
                    type="checkbox"
                    :value="product.id"
                    v-model="selectedProducts"
                  />
                  <span class="checkbox__checkmark"></span>
                </label>
              </td>

              <td class="items-table__td">
                <div class="form-box__mobile-element">Index</div>
                <router-link
                  :to="{
                    name: 'productEdit',
                    params: {
                      id: product.id,
                      lang: $store.state.language.current,
                    },
                  }"
                  class="items-table__router-link"
                >
                  {{ product.index }}
                </router-link>
              </td>

              <td class="items-table__td items-table__td--not-text">
                <div class="form-box__mobile-element">Aktywny</div>
                <div
                  :class="[
                    'items-table__status',
                    {
                      'items-table__status--active': product.is_active == '1',
                    },
                  ]"
                ></div>
              </td>

              <td class="items-table__td items-table__td--expand">
                <div class="form-box__mobile-element">Nazwa</div>
                <span class="items-table__link d-flex align-items-center">
                  <router-link
                    :to="{
                      name: 'productEdit',
                      params: {
                        id: product.id,
                        lang: $store.state.language.current,
                      },
                    }"
                    class="items-table__router-link"
                    >{{ product.name }}</router-link
                  >
                  <span
                    v-if="product.is_new"
                    class="form-box__text-label form-box__text-label--new"
                    >NOWOŚĆ</span
                  >
                  <span
                    v-if="product.is_root"
                    class="form-box__text-label form-box__text-label--root"
                    >PRODUKT GŁÓWNY</span
                  >
                </span>
              </td>

              <td class="items-table__td items-table__td--not-text">
                <div class="form-box__mobile-element">Status synch.</div>
                <div
                  :class="[
                    'm-auto',
                    'items-table__status',
                    'items-table__status--' + wooSyncStatus(product),
                  ]"
                ></div>
              </td>

              <td class="items-table__td">
                <div class="form-box__mobile-element">Kolejność</div>
                <input
                  class="product-form__input"
                  type="text"
                  v-model="product.order"
                  @change="changeProductOrder(product)"
                />
              </td>

              <td class="items-table__td items-table__td--nowrap">
                <div class="form-box__mobile-element">Modyfikacja</div>
                {{ product.updated_at | prettyDate }}
              </td>

              <td class="items-table__td">
                <div class="form-box__mobile-element"></div>
                <ul class="lang-list">
                  <li
                    class="lang-list__item"
                    v-for="lang in languages"
                    :key="lang.id"
                  >
                    <span
                      :class="[
                        'lang-list__link',
                        product.languages.includes(lang.code)
                          ? 'lang-list__link--added'
                          : 'lang-list__link--no-added',
                      ]"
                      @click="goToEditProduct(product, lang.code)"
                    >
                      {{ lang.code }}
                    </span>
                  </li>
                </ul>
              </td>

              <td class="items-table__td pr-0">
                <div class="form-box__buttons">
                  <router-link
                    :to="{
                      name: 'productEdit',
                      params: {
                        id: product.id,
                        lang: $store.state.language.current,
                      },
                    }"
                    class="button-icon"
                  >
                    <icon icon="edit" icon-classes="icon--regular" />
                  </router-link>

                  <button
                    class="button-icon button-icon--ml-10"
                    @click="duplicateProduct(product)"
                  >
                    <icon icon="duplicate" icon-classes="icon--regular" />
                  </button>

                  <button
                    class="button-icon button-icon--ml-10"
                    @click="deleteProduct(product)"
                  >
                    <icon icon="trash" icon-classes="icon--regular" />
                  </button>
                </div>
                <div class="form-box__mobile-element">
                  <label :for="'check-product-' + product.id" class="checkbox">
                    <input
                      :id="'check-product-' + product.id"
                      class="checkbox__input"
                      type="checkbox"
                      :value="product.id"
                      v-model="selectedProducts"
                    />
                    <span class="checkbox__checkmark"></span>
                  </label>
                </div>
              </td>
            </tr>
          </template>
          <!-- end of item -->
        </tbody>
      </table>
    </div>
    <!-- // Products list-->
    <div v-else class="content">
      Brak wyników.
    </div>

    <!-- Pagination -->
    <pagination
      :pagination="products"
      @paginate="getProducts()"
      :offset="1"
    ></pagination>
    <!-- Pagination -->

    <!-- Modal delete product -->
    <modal :show="showModal" size="md" @close="showModal = !showModal">
      <div slot="header" class="notice-modal__header--title">
        Usuwanie produktu
      </div>

      <div slot="body">
        <div>
          Czy na pewno chcesz usunąć produkt
          <strong>{{
            productToDelete.index + " - " + productToDelete.name
          }}</strong
          >?
        </div>
      </div>

      <div slot="footer" class="d-flex align-items-center">
        <button
          class="button button--small button--grey"
          @click="showModal = false"
        >
          Nie
        </button>
        <button
          class="button button--small button--ml-10"
          @click="confirmDeleteProduct()"
        >
          Tak
        </button>
      </div>
    </modal>

    <!-- Modal duplicate product -->
    <modal
      :show="showModalDuplicate"
      size="md"
      @close="showModalDuplicate = !showModalDuplicate"
    >
      <div slot="header" class="notice-modal__header--title">
        Klonowanie produktu
      </div>

      <div slot="body">
        <div>
          Czy na pewno chcesz sklonować produkt
          <strong>{{
            productToDuplicate.index + " - " + productToDuplicate.name
          }}</strong
          >?
        </div>
      </div>

      <div slot="footer" class="d-flex align-items-center">
        <button
          class="button button--small button--grey"
          @click="showModalDuplicate = false"
        >
          Nie
        </button>
        <button
          class="button button--small button--ml-10"
          @click="confirmDuplicateProduct()"
        >
          Tak
        </button>
      </div>
    </modal>
  </div>
  <!-- // Products -->
</template>

<script>
import axios from "axios";
import _ from "lodash";
import Icon from "./parts/Icon";
import Pagination from "./parts/Pagination";
import Modal from "./parts/Modal";
import { wooSyncStatus } from "../helpers/statusWoo";

const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

export default {
  data() {
    return {
      products: {},
      message: "",
      languages: this.$store.state.language.all,
      selectedProducts: [],
      search: "",
      order: "desc",
      orderBy: "created_at",
      showModal: false,
      showModalDuplicate: false,
      productToDelete: {},
      productToDuplicate: {},
      isExporting: false,
    };
  },
  components: {
    Pagination,
    Modal,
    Icon,
  },
  mounted() {
    this.getProducts();

    this.$root.$on("addProductModalSuccess", () => {
      this.getProducts();
    });

    this.$root.$on("exportProducts", () => {
      this.exportAll();
    });
  },
  beforeDestroy() {
    this.$root.$off("addProductModalSuccess");
    this.$root.$off("exportProducts");
  },
  computed: {
    toggleSelectProducts: {
      get: function() {
        return this.products.data
          ? this.selectedProducts.length == this.products.data.length
          : false;
      },
      set: function(value) {
        var selected = [];

        if (value) {
          this.products.data.forEach(function(product) {
            selected.push(product.id);
          });
        }

        this.selectedProducts = selected;
      },
    },
  },
  methods: {
    wooSyncStatus,
    async getProducts() {
      this.selectedProducts = [];

      try {
        var params = {
          page: this.products.current_page,
          order_by: this.orderBy,
          order: this.order,
        };

        if (this.$store.state.language.current)
          params.lang = this.$store.state.language.current;

        if (this.search != "") params.search = this.search;

        const response = await axios.get(API_BASE_URL + "/products", {
          params: params,
        });

        this.products = response.data;
      } catch (error) {
        this.$root.$emit("noticesBox", error.response.data);
      }
    },
    async deleteProduct(product) {
      this.showModal = true;
      this.productToDelete = product;
    },
    async confirmDeleteProduct() {
      await axios.delete(API_BASE_URL + "/products/" + this.productToDelete.id);
      this.showModal = false;
      this.getProducts();
    },
    async duplicateProduct(product) {
      this.showModalDuplicate = true;
      this.productToDuplicate = product;
    },
    confirmDuplicateProduct() {
      let data = {};

      data.product_id = this.productToDuplicate.id;

      axios
        .post(API_BASE_URL + "/products/duplicate", data)
        .then((response) => {
          this.$root.$emit("noticesBox", {
            type: "success",
            message: "Poprawnie sklonowano produkt " + response.name + ".",
          });
          this.showModalDuplicate = false;
          this.getProducts();
        })
        .catch((error) => {
          this.$root.$emit("noticesBox", error.response.data);
        });
    },
    async toggleStatus() {
      try {
        await axios.post(API_BASE_URL + "/products/toggle-status", {
          products: this.selectedProducts,
        });
        this.getProducts();
        this.$root.$emit("noticesBox", {
          type: "success",
          message: "Status produktów został zmieniony.",
        });
      } catch (error) {
        this.$root.$emit("noticesBox", error.response.data);
      }
    },
    sortObjects() {
      event.target.setAttribute("order", this.order == "asc" ? "desc" : "asc");
      this.orderBy = event.target.getAttribute("order-by");
      this.order = event.target.getAttribute("order");

      this.getProducts();
    },
    async exportAll() {
      let data = {
        lang: this.$store.state.language.current,
        order_by: this.orderBy,
        order: this.order,
      };

      this.isExporting = true;

      axios({
        url: API_BASE_URL + "/products/export",
        method: "POST",
        responseType: "blob",
        data: data,
      })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          let timestamp = new Date().getTime();

          fileLink.href = fileURL;
          fileLink.setAttribute(
            "download",
            "products_export_" + timestamp + ".xlsx"
          );
          document.body.appendChild(fileLink);

          fileLink.click();
          this.isExporting = false;
        })
        .catch((error) => {
          this.$root.$emit("noticesBox", error.response.data);
        });
    },
    async exportSelected() {
      let data = {
        lang: this.$store.state.language.current,
        products: this.selectedProducts,
        order_by: this.orderBy,
        order: this.order,
      };

      this.isExporting = true;

      if (this.search != "") data.search = this.search;

      axios({
        url: API_BASE_URL + "/products/export",
        method: "POST",
        responseType: "blob",
        data: data,
      })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          let timestamp = new Date().getTime();

          fileLink.href = fileURL;
          fileLink.setAttribute(
            "download",
            "products_export_" + timestamp + ".xlsx"
          );
          document.body.appendChild(fileLink);

          fileLink.click();
          this.isExporting = false;
        })
        .catch((error) => {
          this.$root.$emit("noticesBox", error.response.data);
        });
    },
    searchProducts: _.debounce(function() {
      this.products.current_page = 1;
      this.getProducts();
    }, 400),
    goToEditProduct(product, lang) {
      let vm = this;
      if (typeof product != "undefined" && product.id) {
        vm.$store
          .dispatch("language/setLanguage", {
            code: lang,
            preventReload: true,
          })
          .then(function() {
            vm.$router.push({
              name: "productEdit",
              params: {
                id: product.id,
                lang: lang,
              },
            });
          });
      }
    },
    async changeProductOrder(product) {
      try {
        await axios.post(
          API_BASE_URL + "/products/" + product.id + "/change-order",
          {
            order: product.order,
          }
        );
        this.getProducts();
        this.$root.$emit("noticesBox", {
          type: "success",
          message: "Kolejność produktów została zmeiniona.",
        });
      } catch (error) {
        this.$root.$emit("noticesBox", error.response.data);
      }
    },
  },
};
</script>
