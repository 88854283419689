<template>
    <div>

        <div class="content-top row">
            <div class="col-24 d-flex justify-content-between align-items-center">
                <div class="content-top__title-box">
                    <h1 class="content-top__heading">Edytuj konto</h1>
                </div>
            </div>
        </div>

        <div class="content product-form product-form--small" v-if="account">

            <div class="row">

                <div class="col-24">
                    <div class="product-form__input-wrap">
                        <div class="product-form__title">Nazwa</div>
                        <input class="product-form__input" type="text" v-model="account.name" autocomplete="new-name"
                            @keypress.enter.prevent="saveAccountData()"  :readonly="isStoring"/>
                    </div>
                </div>

                <div class="col-24">
                    <div class="product-form__input-wrap">
                        <div class="product-form__title">Nowe hasło</div>
                        <div class="product-form__input-with-icon">
                            <input class="product-form__input" :type="this.passwordType" v-model="account.new_password"
                                autocomplete="new-password" @keypress.enter.prevent="saveAccountData()" :readonly="isStoring" />
                            <div class="product-form__view-password" @click="togglePassword">
                                <icon v-if="this.passwordType == 'password'" icon="eye" icon-classes="icon--grey"/>
                                <icon v-else icon="eye" icon-classes="icon--regular" />
                            </div>
                        </div>
                        <div class="product-form__input-hint">Pozostaw puste, aby nie zmieniać hasła.</div>
                    </div>
                </div>

                <div class="col-24">
                    <div class="product-form__input-wrap">
                        <div class="product-form__title">E-mail</div>
                        <input class="product-form__input" type="email" v-model="account.email" autocomplete="new-email"
                            @keypress.enter.prevent="saveAccountData()" :readonly="isStoring"/>
                    </div>
                </div>

                <div class="col-24">
                    <div class="product-form__input-wrap">
                        <div class="product-form__title">Uprawnienia</div>
                        <treeselect v-model="account.role" placeholder="" :multiple="false" :options="roles"
                            :clearable="false" :disabled="true" />
                    </div>
                </div>

                <div class="col-24">
                    <button class="button button--icon" @click="saveAccountData()" :disabled="isStoring">
                        <div class="loader button__icon button__icon--left" v-if="isStoring"></div>
                        <icon v-else icon="save" icon-classes="button__icon button__icon--left icon--white" />
                        <span class="button__text">Zapisz</span>
                    </button>
                </div>

            </div>

        </div>
        <div v-else class="content">
            Ładuję dane konta...
        </div>

    </div>
</template>

<script>
    import axios from 'axios';
    const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
    import Icon from './parts/Icon';
    import Treeselect from '@riophae/vue-treeselect';

    export default {
        data() {
            return {
                objects: {},
                account: false,
                isStoring: false,
                passwordType: 'password',
                roles: [{
                    id: 'admin',
                    label: 'Administrator'
                }, {
                    id: 'user',
                    label: 'Użytkownik'
                }]
            }
        },
        components: {
            Icon,
            Treeselect
        },
        mounted() {
            let vm = this;
            axios.get(API_BASE_URL + '/users/' + this.$store.state.auth.user.id)
                .then(response => {
                    vm.account = response.data;
                })
                .catch(error => {
                    vm.$root.$emit('noticesBox', error.response.data);
                });
        },
        methods: {
            async saveAccountData() {
                let data = this.account;

                let user = JSON.parse(localStorage.getItem('user'));
                user.name = data.name;
                localStorage.setItem('user', JSON.stringify(user));
                this.$root.$emit('updateUsername', data.name);

                this.isStoring = true;

                try {
                    const response = await axios.post(API_BASE_URL + '/users', data);

                    this.isStoring = false;
                    this.$root.$emit('noticesBox', {
                        type: 'success',
                        message: response.data.message
                    });

                } catch (error) {
                    this.isStoring = false;
                    this.$root.$emit('noticesBox', error.response.data);
                }
            },
            togglePassword() {
                this.passwordType = ((this.passwordType == 'password') ? 'text' : 'password');
            }
        }
    }
</script>