<template>
  <div>
    <div class="content-top row">
      <div class="col-24 d-flex justify-content-between align-items-center">
        <div class="content-top__title-box">
          <h1 class="content-top__heading">Lista instalacji produktów</h1>
          <div class="content-top__subheading">
            {{ objects.length }}
            {{
              objects.length
                | numeralsText("instalacja", "instalacje", "instalacji")
            }}
          </div>
        </div>
        <button class="button button--icon" @click="addObject()">
          <icon
            icon="plus"
            icon-classes="button__icon button__icon--left icon--white icon__plus--small"
          />
          <span class="button__text">Dodaj nową instalację</span>
        </button>
      </div>
    </div>

    <div class="content row">
      <div class="col-24">
        <table class="items-table">
          <thead class="items-table__thead">
            <tr class="items-table__tr">
              <th class="items-table__th">
                ID
              </th>
              <th class="items-table__th">
                Nazwa
              </th>
              <th class="items-table__th p-0 text-right">
                Akcje
              </th>
            </tr>
          </thead>

          <tbody class="items-table__tbody">
            <template v-for="object in objects">
              <tr class="items-table__tr" v-bind:key="object.id">
                <td class="items-table__td">
                  <div class="form-box__mobile-element">Id</div>
                  {{ object.id }}
                </td>

                <td class="items-table__td items-table__td--expand">
                  <div class="form-box__mobile-element">Nazwa</div>
                  {{ object.name }}
                </td>

                <td class="items-table__td items-table__td--button">
                  <div class="form-box__mobile-element">Akcje</div>
                  <div class="d-flex justify-content-end">
                    <button
                      class="button-icon"
                      @click.prevent="editObject(object)"
                    >
                      <icon icon="edit" />
                    </button>

                    <button
                      class="button-icon button-icon--ml-10"
                      @click="deleteObject(object)"
                    >
                      <icon icon="trash" />
                    </button>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Modal add/edit -->
    <modal :show="showModal" size="lg" @close="showModal = !showModal">
      <div slot="header" class="notice-modal__header--title">
        <span
          v-text="!isEditing ? 'Dodawanie instalacji' : 'Edycja instalacji'"
        ></span>
      </div>

      <div slot="body">
        <div class="row">
          <div class="col-24">
            <div class="product-form__title">Nazwa</div>
            <input
              class="product-form__input"
              type="text"
              v-model="object.name"
              required
            />
          </div>
        </div>
      </div>

      <div slot="footer" class="d-flex align-items-center">
        <button
          class="button button--small button--grey"
          @click="showModal = false"
          :disabled="isStoring"
        >
          Anuluj
        </button>
        <button
          class="button button--small button--ml-10"
          @click="storeObject()"
          :disabled="isStoring"
          v-text="isStoring ? 'Zapisuję...' : 'Zapisz'"
        >
          Zapisz
        </button>
      </div>
    </modal>

    <!-- Modal delete -->
    <modal
      :show="showDeleteModal"
      size="md"
      @close="showDeleteModal = !showDeleteModal"
    >
      <div slot="header" class="notice-modal__header--title">
        Usuwanie instalacji
      </div>

      <div slot="body">
        <div>
          Czy na pewno chcesz usunąć instalację
          <strong>{{
            "#" + objectToDelete.id + " - " + objectToDelete.name
          }}</strong
          >?
        </div>
      </div>

      <div slot="footer" class="d-flex align-items-center">
        <button
          class="button button--small button--grey"
          @click="showDeleteModal = false"
        >
          Nie
        </button>
        <button
          class="button button--small button--ml-10"
          @click="confirmDeleteObject()"
        >
          Tak
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import axios from "axios";
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
import Icon from "./../parts/Icon";
import Modal from "./../parts/Modal";
import _ from "lodash";

export default {
  data() {
    return {
      objects: {},
      object: {},
      showModal: false,
      showDeleteModal: false,
      isEditing: false,
      isStoring: false,
      imagePath: false,
      objectToDelete: {},
    };
  },
  components: {
    Icon,
    Modal,
  },
  async created() {
    this.getObjects();
  },
  methods: {
    async storeObject() {
      let vm = this;
      let data = this.object;
      this.isStoring = true;

      try {
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };

        let formData = new FormData();
        if (data.id) {
          formData.append("id", data.id);
        }
        if (data.name) {
          formData.append("name", data.name);
        }

        await axios.post(
          API_BASE_URL + "/product-installations",
          formData,
          config
        );

        vm.getObjects();
        vm.object = {};
        vm.showModal = false;
        vm.isStoring = false;
      } catch (error) {
        vm.isStoring = false;
        vm.$root.$emit("noticesBox", error.response.data);
      }
    },
    async getObjects() {
      try {
        const response = await axios.get(
          API_BASE_URL + "/product-installations"
        );
        this.objects = response.data;
      } catch (error) {
        this.$root.$emit("noticesBox", error.response.data);
      }
    },
    editObject(object) {
      this.object = _.cloneDeep(object);
      this.isEditing = true;
      this.showModal = true;
    },
    addObject() {
      this.object = {};
      this.isEditing = false;
      this.showModal = true;
    },
    async deleteObject(object) {
      this.showDeleteModal = true;
      this.objectToDelete = object;
    },
    async confirmDeleteObject() {
      this.showDeleteModal = false;
      try {
        await axios.delete(
          API_BASE_URL + "/product-installations/" + this.objectToDelete.id
        );
        this.getObjects();
      } catch (error) {
        this.$root.$emit("noticesBox", error.response.data);
      }
    },
  },
};
</script>
