<template>

    <div id="files-upload">

        <div class="content-top row">
            <div class="col-24 d-flex justify-content-between align-items-center">
                <div class="content-top__title-box">
                    <h1 class="content-top__heading">Dodaj pliki</h1>
                    <div class="content-top__subheading">
                        <template v-if="onGoingUploads > 0">Trwa wysyłanie {{ onGoingUploads }} {{ onGoingUploads | numeralsText('pliku', 'plików', 'plików') }} ...</template>
                        <template v-else-if="countNotCompleted() > 0">{{ countNotCompleted() }} {{ countNotCompleted() | numeralsText('plik gotowy', 'pliki gotowe', 'pliki gotowe') }} do wysłania</template>
                        <template v-else>Wybierz pliki do wysłania</template>
                    </div>
                </div>

                <div class="upload-button-container">
                    <button class="button button--upload" :class="{'is-completed' : uploadCompleted}" :disabled="onGoingUploads > 0 || forms.length <= 0 || countNotCompleted() == 0" @click.prevent="upload()">
                        <div class="loader button__icon button__icon--left" v-if="onGoingUploads > 0"></div>
                        <span v-text="(onGoingUploads > 0) ? 'Zapisuję' : 'Zapisz'"></span>
                    </button>
                </div>
            </div>
        </div>

        <div class="row" v-if="forms.length > 0">
            <div class="col-24">
                <div class="files-upload"
                    :class="{'is-dragover' : isDragOver}" @dragenter="isDragOver = true" @dragleave="isDragOver = false" @drop.prevent="addFile" @dragover.prevent>
                    <div class="row file-form" :class="{'is-completed' : form.completed}" v-for="(form, index) in forms"
                        :key="index">
                        <div class="file-form__data col-24 col-xl-4 d-flex align-items-center pl-0">
                            <div class="files-upload__icon" v-if="form.icon"
                                :style="{ backgroundImage: 'url(' + form.icon + ')'}"></div>
                            <span class="file-form__name">{{form.file.name}} ({{ form.file.size | prettyBytes }})</span>
                        </div>
                        <div class="file-form__data col-24 col-xl-6">
                            <input v-if="!form.completed" class="file-form__input" :readonly="form.isUploading" :placeholder="(form.isUploading ? '' : 'Kliknij aby dodać nazwę wyświetlaną...')" v-model="form.display_name" />
                            <div v-else>{{ form.display_name }}</div>
                        </div>
                        <div class="file-form__data col-24 col-xl-8">
                            <input v-if="!form.completed" class="file-form__input" :readonly="form.isUploading" :placeholder="(form.isUploading ? '' : 'Kliknij aby dodać krótki opis...')" v-model="form.description" />
                            <div v-else>{{ form.description }}</div>
                        </div>
                        <div class="file-form__data col-24 col-xl-4">
                            <treeselect v-if="!form.completed" v-model="form.type" @input="selectIcon(form)" value-format="object" :multiple="false" :options="fileTypes"  placeholder="Wybierz typ pliku..." :normalizer="normalizer" :clearable="false"/>
                            <div v-else>{{ form.type.name }}</div>
                        </div>
                        <div class="file-form__data col-24 col-xl-2 pr-0">
                            <div class="d-flex justify-content-end">
                                <button class="button-icon" v-if="!form.completed" @click="removeForm(form)">
                                    <icon icon="delete"></icon>
                                </button>
                                <button v-else class="button-icon button-icon--success"  @click="removeForm(form)">
                                    <icon icon="check"></icon>
                                </button>
                            </div>
                        </div>
                        
                        <div class="col-24 d-flex file-form__messages pl-0 pr-0" v-if="form.message">
                            <div class="file-form__message" :class="'is-' + form.status">
                                {{ form.message }}
                            </div>
                        </div>
                        <div class="col-24 d-flex file-form__messages pl-0 pr-0" v-if="form.errors">
                            <div class="file-form__message" :class="'is-' + form.status" v-for="(error, index) in form.errors" :key="'error_' + index">
                                <template v-for="text in error">{{ text }}</template>
                            </div>
                        </div>
                        <progress class="file-form__progress" max="100" :value.prop="form.progress" :class="'is-' + form.status"></progress>
                    </div>
                </div>
            </div>
            <div class="col-24 d-flex justify-content-end files-upload__footer">
                <button for="addFilesToUpload" class="button button--icon" :disabled="onGoingUploads > 0" @click="$refs.fileUpload.click()">
                    <icon icon="plus" icon-classes="button__icon button__icon--left icon--white icon__plus--small"/>
                    <span class="button__text">Dodaj kolejne</span>
                </button>
                <button class="button button--upload button--ml-10" :class="{'is-completed' : uploadCompleted}" :disabled="onGoingUploads > 0 || forms.length <= 0 || countNotCompleted() == 0" @click.prevent="upload()">
                    <div class="loader button__icon button__icon--left" v-if="onGoingUploads > 0"></div>
                    <span v-text="(onGoingUploads > 0) ? 'Zapisuję' : 'Zapisz'"></span>
                </button>
            </div>
        </div>

        <div class="row" v-else>
            <div class="col-24">
                <div v-cloak class="files-drop-area"
                    :class="{'is-dragover' : isDragOver}" @dragenter="isDragOver = true" @dragleave="isDragOver = false" @drop.prevent="addFile" @dragover.prevent>
                    <div class="files-drop-area__wrap">
                        <template v-if="isDragOver">
                            <span class="files-drop-area__msg">Upuść tutaj, aby dodać plik(i)</span>
                        </template>
                        <template v-else>
                            <span class="files-drop-area__msg">Przeciągnij i upuść tutaj<br />lub</span>&nbsp;<span
                                class="files-drop-area__button" @click="$refs.fileUpload.click()">Wybierz z dysku</span>
                        </template>
                    </div>
                </div>
            </div>
        </div>

        <input class="files-upload__add-files-input" ref="fileUpload" type="file" @change="addFile" multiple="true" />

    </div>
</template>

<script>
    import axios from 'axios';
    import Icon from './../parts/Icon';
    const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
    import Treeselect from '@riophae/vue-treeselect';

    export default {
        data() {
            return {
                files: [],
                fileTypes: [],
                forms: [],
                isDragOver: false,
                disableUpload: true,
                onGoingUploads: false,
                uploadCompleted: false,
                defaultIcon: require('./../../iconfont/default-file-icon.svg'),
                selectedForm: {},
                message: '',
                normalizer(node) {
                    return {
                        id: node.id,
                        label: node.name
                    }
                }
            }
        },
        components:{
            Icon,
            Treeselect
        },
        watch: {
            onGoingUploads: {
                handler(newVal) {
                    if (newVal == 0) {
                        this.uploadCompleted = true;
                        this.disableUpload = false;
                    }
                },
                deep: true,
                immediate: false
            }
        },
        created() {
            this.getFileTypes();
        },
        methods: {
            addFile(e) {
                let vm = this;
                vm.disableUpload = false;
                var selectedFiles = e.target.files || e.dataTransfer.files;
                if (!selectedFiles) return;
                vm.isDragOver = false;

                ([...selectedFiles]).forEach(f => {

                    let preparedForm = {
                        name: f.name,
                        completed: false,
                        display_name: '',
                        description: '',
                        file: f,
                        icon: require('./../../iconfont/default-file-icon.svg'),
                        progress: 0,
                        status: 'default',
                        isUploading: false,
                        errors: {}
                    };

                    let extension = f.name.substring(f.name.lastIndexOf('.')+1);
                    
                    vm.fileTypes.forEach((fileType) => {
                        if(fileType.ext.toLowerCase() == extension.toLowerCase()){
                            preparedForm.type = fileType;
                            return;
                        } else if(fileType.ext == '*'){
                            preparedForm.type = fileType;
                        }
                    });

                    vm.forms.push(preparedForm);
                });

                vm.$refs.fileUpload.value = '';

            },
            async getFileTypes() {
                this.response = {};
                try {
                    const response = await axios.get(API_BASE_URL + '/files/types');
                    this.fileTypes = response.data;
                } catch (error) {
                    this.$root.$emit('noticesBox', error.response.data);
                }
            },
            removeForm(form) {
                this.forms = this.forms.filter(f => {
                    return f != form;
                });
            },
            async upload() {
                let vm = this;
                vm.disableUpload = true;
                vm.onGoingUploads = this.countNotCompleted();

                vm.forms.forEach((form) => {
                    let thisForm = form;

                    if (form.completed) return;

                    thisForm.errors = {};
                    thisForm.status = 'default';

                    let formData = new FormData();
                    formData.append('file', form.file);
                    formData.append('display_name', form.display_name);
                    formData.append('description', form.description);
                    if (form.type) {
                        formData.append('type_id', form.type.id);
                    }
                    thisForm.isUploading = true;

                    const config = {
                        headers: {
                            'content-type': 'multipart/form-data'
                        },
                        onUploadProgress: function (progressEvent) {
                            form.progress = parseInt(Math.round((progressEvent.loaded /
                                progressEvent.total) * 100));
                        }.bind(vm)
                    }

                    axios.post(API_BASE_URL + '/files/upload', formData, config)
                        .then(function (response) {
                            thisForm.completed = true;
                            vm.onGoingUploads--;
                            thisForm.isUploading = false;
                            thisForm.status = 'success';
                            thisForm.message = response.data.message;
                        })
                        .catch(function (error) {
                            if(error.response.data.errors){
                                thisForm.errors = error.response.data.errors;
                            }
                            if(error.response.data.errors){
                                thisForm.message = error.response.data.message;
                            }
                            vm.onGoingUploads--;
                            thisForm.isUploading = false;
                            thisForm.status = 'error';
                        });
                });

            },
            countNotCompleted(){
                let count = 0;
                this.forms.forEach(function(form){
                    if(!form.completed) count++;
                });

                return count;
            },
            selectIcon(form) {
                form.icon = (form.type.icon) ? API_BASE_URL + '/' + form.type.icon : this.defaultIcon;
            }

        }
    }
</script>