<template>
    <div>

        <div class="content-top row">
            <div class="col-24 d-flex justify-content-between align-items-center">
                <div class="content-top__title-box">
                    <h1 class="content-top__heading">Dodaj użytkownika</h1>
                </div>
            </div>
        </div>

        <div class="content product-form product-form--small">

            <div class="row">

                <div class="col-24">
                    <div class="product-form__input-wrap">
                        <div class="product-form__title">Nazwa</div>
                        <input class="product-form__input" type="text" v-model="newObject.name"
                            autocomplete="new-name" @keypress.enter.prevent="storeObject()"/>
                    </div>
                </div>

                <div class="col-24">
                    <div class="product-form__input-wrap">
                        <div class="product-form__title">Hasło</div>
                        <input class="product-form__input" :type="this.passwordType" v-model="newObject.password"
                            autocomplete="new-password" @keypress.enter.prevent="storeObject()"/>
                        <div class="product-form__view-password" @click="togglePassword">
                            <icon v-if="this.passwordType == 'password'" icon="eye" icon-classes="icon--grey" />
                            <icon v-else icon="eye" icon-classes="icon--regular" />
                        </div>
                    </div>
                </div>

                <div class="col-24">
                    <div class="product-form__input-wrap">
                        <div class="product-form__title">E-mail</div>
                        <input class="product-form__input" type="email" v-model="newObject.email"
                            autocomplete="new-email" @keypress.enter.prevent="storeObject()"/>
                    </div>
                </div>

                <div class="col-24">
                    <div class="product-form__input-wrap">
                        <div class="product-form__title">Uprawnienia</div>
                        <treeselect v-model="newObject.role" placeholder="" :multiple="false" :options="roles"
                            :clearable="false" />
                    </div>
                </div>

                <div class="col-24">
                    <button class="button button--icon" @click="storeObject()" :disabled="isStoring">
                        <div class="loader button__icon button__icon--left" v-if="isStoring"></div>
                        <icon v-else icon="plus"
                            icon-classes="button__icon button__icon--left icon--white icon__plus--small" />
                        <span class="button__text">Dodaj użytkownika</span>
                    </button>
                </div>

            </div>

        </div>

    </div>
</template>

<script>
    import axios from 'axios';
    const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
    import Icon from './../parts/Icon';
    import Treeselect from '@riophae/vue-treeselect';

    export default {
        data() {
            return {
                newObject: {},
                passwordType: 'password',
                roles: [{
                    id: 'admin',
                    label: 'Administrator'
                }, {
                    id: 'user',
                    label: 'Użytkownik'
                }]
            }
        },
        components: {
            Icon,
            Treeselect
        },
        methods: {
            async storeObject() {
                let data = this.newObject;

                try {
                    const response = await axios.post(API_BASE_URL + '/users', data);
                    this.newObject = {};

                    this.$root.$emit('noticesBox', {
                        type: 'success',
                        message: response.data.message
                    });

                } catch (error) {
                    this.$root.$emit('noticesBox', error.response.data);
                }
            },
            togglePassword() {
                this.passwordType = ((this.passwordType == 'password') ? 'text' : 'password');
            }
        }
    }
</script>