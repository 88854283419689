<template>
    <div>

        <div class="content-top row">
            <div class="col-24 d-flex justify-content-between align-items-center">
                <div class="content-top__title-box">
                    <h1 class="content-top__heading">Lista języków</h1>
                    <div class="content-top__subheading">{{ languages.length }}
                        {{ languages.length | numeralsText('język', 'języki', 'języków') }}</div>
                </div>
            </div>
        </div>

        <div class="content row">
            <div class="col-24">

                <table class="items-table items-table--shrink">
                    <thead class="items-table__thead">
                        <tr class="items-table__tr">
                            <th class="items-table__th">
                                Kod
                            </th>
                            <th class="items-table__th text-center">
                                Waluta
                            </th>
                            <th class="items-table__th text-center">
                                Domyślny
                            </th>
                            <th class="items-table__th p-0 text-right">
                                Akcje
                            </th>
                        </tr>
                    </thead>

                    <tbody class="items-table__tbody">

                        <template v-for="lang in languages">
                            <tr class="items-table__tr" v-bind:key="lang.code">
                                <td class="items-table__td">
                                    <div class="form-box__mobile-element">Kod</div>
                                    {{ lang.code | uppercase }}
                                </td>

                                <td class="items-table__td text-center">
                                    <div class="form-box__mobile-element">Waluta</div>
                                    <div v-if="editingObject.key == lang.code">
                                        <input type="text" class="items-table__input items-table__input--sm"
                                            v-model="editingObject.currency" @keypress.enter.prevent="saveObject()"/>
                                    </div>
                                    <div v-else>
                                        {{ lang.currency | uppercase }}
                                    </div>
                                </td>

                                <td class="items-table__td">
                                    <div class="form-box__mobile-element">Domyślny</div>
                                    <div class="d-flex justify-content-center">
                                        <div
                                            :class="['items-table__status', {'items-table__status--default' : lang.default == '1'}]">
                                        </div>
                                    </div>
                                </td>

                                <td class="items-table__td items-table__td--button">
                                    <div class="form-box__mobile-element">Akcje</div>
                                    <div class="d-flex justify-content-end">
                                        <button v-if="editingObject.key == lang.code" class="button-icon"
                                            @click.prevent="saveObject()">
                                            <icon icon="save"/>
                                        </button>
                                        <button v-else class="button-icon" @click.prevent="editObject(lang)">
                                            <icon icon="edit" />
                                        </button>

                                        <button class="button-icon button-icon--ml-10" @click="deleteLanguage(lang)"
                                            v-if="!lang.default">
                                            <icon icon="trash" />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </template>

                        <tr class="items-table__tr">
                            <td class="items-table__td">
                                <div class="form-box__mobile-element">Kod</div>
                                <input v-model="newLang.code" maxlength="2"
                                    class="items-table__input items-table__input--xs" @keypress.enter.prevent="storeLang()"/>
                            </td>
                            <td class="items-table__td">
                                <div class="form-box__mobile-element">Waluta</div>
                                <input v-model="newLang.currency" maxlength="3"
                                    class="items-table__input items-table__input--sm" @keypress.enter.prevent="storeLang()"/>
                            </td>
                            <td class="items-table__td items-table__td--buttom" colspan="2">
                                <div class="form-box__mobile-element"></div>
                                <div class="d-flex justify-content-end">

                                    <button class="button button--icon" @click="storeLang()">
                                        <icon icon="plus"
                                            icon-classes="button__icon button__icon--left icon--white icon__plus--small" />
                                        <span class="button__text">Dodaj nowy język</span>
                                    </button>
                                </div>
                            </td>
                        </tr>

                    </tbody>
                </table>

            </div>
        </div>

        <!-- Modal delete -->
        <modal :show="showModal" size="md" @close="showModal = !showModal;">
            <div slot="header" class="notice-modal__header--title">
                Usuwanie języka
            </div>

            <div slot="body">
                <div>Czy na pewno chcesz usunąć język <strong>{{ langToDelete.code | uppercase }}</strong>?</div>
            </div>

            <div slot="footer" class="d-flex align-items-center">
                <button class="button button--small button--grey" @click="showModal = false">
                    Nie
                </button>
                <button class="button button--small button--ml-10" @click="confirmDeleteLanguage()">
                    Tak
                </button>
            </div>
        </modal>

    </div>
</template>

<script>
    import axios from 'axios';
    const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
    import Icon from './parts/Icon';
    import Modal from './parts/Modal';

    export default {
        data() {
            return {
                languages: {},
                showModal: false,
                langToDelete: false,
                editingObject: {},
                newLang: {
                    default: 0
                }
            }
        },
        components: {
            Icon,
            Modal
        },
        async created() {
            this.getLangs();
        },
        methods: {
            async storeLang(data) {
                if (!data) data = this.newLang;
                try {
                    const response = await axios.post(API_BASE_URL + '/languages', data);
                    this.languages = response.data;
                    this.editingObject = {};
                    this.newLang = {};
                    this.$store.dispatch('language/fetchLanguages');
                } catch (error) {
                    this.$root.$emit('noticesBox', error.response.data);
                }
            },
            async getLangs() {
                this.response = {};
                try {
                    const response = await axios.get(API_BASE_URL + '/languages');
                    this.languages = response.data;
                } catch (error) {
                    this.$root.$emit('noticesBox', error.response.data);
                }
            },
            async deleteLanguage(lang) {
                this.showModal = true;
                this.langToDelete = lang;
            },
            async confirmDeleteLanguage() {
                this.response = {};
                this.showModal = false;
                try {
                    await axios.delete(API_BASE_URL + '/languages/' + this.langToDelete.code);
                    this.getLangs();
                } catch (error) {
                    this.$root.$emit('noticesBox', error.response.data);
                }
            },
            editObject(object) {
                this.editingObject = object;
                this.editingObject.key = object.code;
            },
            async saveObject() {
                this.storeLang(this.editingObject);
            }
        }
    }
</script>