<template>
  <div v-if="localProduct">
    <input
      type="file"
      ref="photosInput"
      @change="onImageChange"
      :disabled="isStoring"
      style="display: none"
    />
    <div class="product-form__title">{{ name }}</div>

    <button
      class="button button--icon mb-3"
      @click="$refs.photosInput.click()"
      :disabled="isStoring"
      v-if="
        Array.isArray(localProduct[photoType]) ||
        !localProduct[photoType] ||
        !Object.keys(localProduct[photoType]).length
      "
    >
      <icon
        v-if="!isStoring"
        icon="plus"
        icon-classes="icon--white button__icon button__icon--left icon--white"
      ></icon>
      <div v-else class="loader button__icon button__icon--left"></div>
      <span
        class="button__text"
        v-text="isStoring ? 'Wysyłanie...' : 'Dodaj zdjęcie'"
      >
        Dodaj zdjęcie</span
      >
    </button>
    <div class="row" v-if="Array.isArray(localProduct[photoType])">
      <div
        :class="'col-' + column"
        v-for="photo in localProduct[photoType]"
        :key="photo.id"
      >
        <div class="product-photo">
          <img :src="getApiPath(photo.path)" class="product-photo__img" />
          <div
            class="product-photo__actions d-flex align-items-center justify-content-end"
          >
            <button
              class="button-icon button-icon--ml-10 button-icon--bg-white"
              :disabled="isStoring"
              @click="deleteObject(photo)"
            >
              <icon icon="delete" />
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else-if="
        localProduct[photoType] && Object.keys(localProduct[photoType]).length
      "
      class="row"
    >
      <div :class="'col-' + column">
        <div class="product-photo">
          <img
            :src="getApiPath(localProduct[photoType].path)"
            class="product-photo__img"
          />
          <div
            class="product-photo__actions d-flex align-items-center justify-content-end"
          >
            <button
              class="button-icon button-icon--ml-10 button-icon--bg-white"
              :disabled="isStoring"
              @click="deleteObject(localProduct[photoType])"
            >
              <icon icon="delete" />
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal delete -->
    <modal :show="showModal" size="md" @close="showModal = !showModal">
      <div slot="header" class="notice-modal__header--title">
        Usuwanie zdjecia produktu
      </div>

      <div slot="body">
        Czy na pewno chcesz usunąć powiązanie zdjęcia z produktem?
        <div class="product-photo mt-4">
          <img :src="getApiPath(objectToDelete.path)" />
        </div>
      </div>

      <div slot="footer" class="d-flex align-items-center">
        <button
          class="button button--small button--grey"
          @click="showModal = false"
        >
          Nie
        </button>
        <button
          class="button button--small button--ml-10"
          @click="confirmdeleteObject()"
        >
          Tak
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import axios from "axios";
import _ from "lodash";
import Icon from "../parts/Icon";
import Modal from "../parts/Modal";
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
const API_PHOTO_URL = process.env.VUE_APP_API_PHOTO_URL;

export default {
  data() {
    return {
      localProduct: false,
      image: "",
      imagePath: "",
      isStoring: false,
      showModal: false,
      objectToDelete: false,
    };
  },
  components: {
    Icon,
    Modal,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    photoType: {
      type: String,
    },
    photoTypeName: {
      type: String,
    },
    name: {
      type: String,
    },
    column: {
      default: "24",
      type: String,
    },
  },
  watch: {
    product: {
      handler(newVal) {
        this.isLoaded = true;
        this.localProduct = _.cloneDeep(newVal);
      },
      deep: true,
      immediate: false,
    },
  },
  methods: {
    savePhoto() {
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      let vm = this;
      let formData = new FormData();

      vm.isStoring = true;

      formData.append("image", this.image);
      formData.append("product_id", this.$route.params.id);
      formData.append("type", this.photoType);

      vm.$emit("storingProduct", true);

      axios
        .post(API_BASE_URL + "/products/photos", formData, config)
        .then(function (response) {
          vm.localProduct[vm.photoType] = _.cloneDeep(
            response.data[vm.photoType]
          );
          vm.$emit("storingProduct", false);
          vm.$emit("onChangeee");
          vm.isStoring = false;

          vm.$root.$emit("noticesBox", {
            type: "success",
            message: "Zdjęcie zostało zapisane.",
          });
        })
        .catch(function (error) {
          vm.$emit("storingProduct", false);
          vm.isStoring = false;
          vm.$root.$emit("noticesBox", error.response.data);
        });

      this.$refs.photosInput.value = "";
    },
    onImageChange(e) {
      this.image = e.target.files[0];
      this.savePhoto();
    },
    getApiPath(path) {
      if (path) {
        let timestamp = new Date().getTime();

        return API_PHOTO_URL + path + "?" + timestamp;
      } else return require("../../img/default.png");
    },
    async deleteObject(object) {
      this.showModal = true;
      this.objectToDelete = object;
    },
    confirmdeleteObject() {
      let vm = this;
      vm.$emit("onChangeee");
      this.showModal = false;
      axios
        .delete(
          API_BASE_URL +
            "/products/photos/delete/" +
            this.objectToDelete.relation_id
        )
        .then(function () {
          if (Array.isArray(vm.localProduct[vm.photoType])) {
            const indexToRemove = vm.localProduct[vm.photoType].findIndex(
              (photo) => photo.id === vm.objectToDelete.id
            );
            if (indexToRemove !== -1) {
              console.log(indexToRemove);
              vm.localProduct[vm.photoType].splice(indexToRemove, 1);
            }
          } else {
            vm.localProduct[vm.photoType] = _.cloneDeep({});
          }

          vm.$root.$emit("noticesBox", {
            type: "success",
            message: "Usunięto powiązanie.",
          });
        })
        .catch(function (error) {
          this.$root.$emit("noticesBox", error.response.data);
        });
    },
  },
};
</script>
