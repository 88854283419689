export function wooSyncStatus(product) {
  const statuses = {
    "110": "active",
    "001": "error",
    "101": "error",
    "111": "error",
    "000": "empty",
  };
  var statusString;
  if (product) {
    statusString = `${product.woo_added}${product.woo_updated}${product.woo_error}`;
  } else {
    statusString = `${this.wooStatusess.woo_added}${this.wooStatusess.woo_updated}${this.wooStatusess.woo_error}`;
  }
  return statuses[statusString] || "default";
}
