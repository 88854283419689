import Vue from "vue";
import Router from "vue-router";
import store from "./store/index";

// Components
import Dashboard from "./components/Dashboard.vue";
import ActivityLog from "./components/ActivityLog.vue";
import Login from "./components/Login.vue";
import ResetPassword from "./components/ResetPassword.vue";
import Languages from "./components/Languages.vue";

// -- Users
import UsersList from "./components/users/UsersList.vue";
import UsersStore from "./components/users/UsersStore.vue";

// -- Account
import AccountEdit from "./components/AccountEdit.vue";

// -- Products
import ProductsList from "./components/ProductsList.vue";
import ProductEdit from "./components/ProductEdit.vue";
import ProductsImport from "./components/ProductsImport.vue";

// -- Dicionaries
import DictionariesList from "./components/dictionaries/DictionariesList.vue";
import DictionariesProductBowlPositions from "./components/dictionaries/ProductBowlPositions.vue";
import DictionariesProductBrands from "./components/dictionaries/ProductBrands.vue";
import DictionariesProductCategories from "./components/dictionaries/ProductCategories.vue";
import DictionariesProductColors from "./components/dictionaries/ProductColors.vue";
import DictionariesProductInstallations from "./components/dictionaries/ProductInstallations.vue";
import DictionariesProductOutflowDiameters from "./components/dictionaries/ProductOutflowDiameters.vue";
import DictionariesProductOverflows from "./components/dictionaries/ProductOverflows.vue";
import DictionariesProductRegions from "./components/dictionaries/ProductRegions.vue";
import DictionariesProductMaterials from "./components/dictionaries/ProductMaterials.vue";
import DictionariesProductShapes from "./components/dictionaries/ProductShapes.vue";
import DictionariesProductStructures from "./components/dictionaries/ProductStructures.vue";
import DictionariesProductSurfaces from "./components/dictionaries/ProductSurfaces.vue";
import DictionariesProductTechnologies from "./components/dictionaries/ProductTechnologies.vue";
import DictionariesProductWarranties from "./components/dictionaries/ProductWarranties.vue";
import DictionariesProductAttributes from "./components/dictionaries/ProductAttributes.vue";

// -- Files
import Files from "./components/files/Files.vue";
import FilesUpload from "./components/files/FilesUpload.vue";
import DictionariesFileTypes from "./components/files/FileTypes.vue";

Vue.use(Router);

export const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "dashboard",
      component: Dashboard,
      meta: {
        submenu: "dashboard",
      },
    },
    {
      path: "/login",
      name: "login",
      component: Login,
    },
    {
      path: "/reset-password",
      name: "resetPassword",
      component: ResetPassword,
    },
    {
      path: "/activity-log",
      name: "activityLog",
      component: ActivityLog,
      meta: {
        submenu: "activity",
      },
    },
    {
      path: "/users-list",
      name: "usersList",
      component: UsersList,
      meta: {
        submenu: "users",
      },
    },
    {
      path: "/users-store",
      name: "usersStore",
      component: UsersStore,
      meta: {
        submenu: "users",
      },
    },
    {
      path: "/edit-account",
      name: "accountEdit",
      component: AccountEdit,
    },
    {
      path: "/products",
      name: "productsList",
      component: ProductsList,
      meta: {
        submenu: "products",
      },
    },
    {
      path: "/product/:id/edit/:lang",
      name: "productEdit",
      component: ProductEdit,
      meta: {
        submenu: "products",
      },
    },
    {
      path: "/products/import",
      name: "productsImport",
      component: ProductsImport,
      meta: {
        submenu: "products",
      },
    },
    {
      path: "/dictionaries",
      name: "dictionariesList",
      component: DictionariesList,
      meta: {
        submenu: "dictionaries",
        adminAuth: true,
      },
      children: [
        {
          path: "product-brands",
          name: "dictionariesProductBrands",
          component: DictionariesProductBrands,
          meta: {
            submenu: "dictionaries",
          },
        },
        {
          path: "product-outflow-diameters",
          name: "dictionariesProductOutflowDiameters",
          component: DictionariesProductOutflowDiameters,
          meta: {
            submenu: "dictionaries",
          },
        },
        {
          path: "product-overflows",
          name: "dictionariesProductOverflows",
          component: DictionariesProductOverflows,
          meta: {
            submenu: "dictionaries",
          },
        },
        {
          path: "product-regions",
          name: "dictionariesProductRegions",
          component: DictionariesProductRegions,
          meta: {
            submenu: "dictionaries",
          },
        },
        {
          path: "product-categories",
          name: "dictionariesProductCategories",
          component: DictionariesProductCategories,
          meta: {
            submenu: "dictionaries",
          },
        },
        {
          path: "product-colors",
          name: "dictionariesProductColors",
          component: DictionariesProductColors,
          meta: {
            submenu: "dictionaries",
          },
        },
        {
          path: "product-installations",
          name: "dictionariesProductInstallations",
          component: DictionariesProductInstallations,
          meta: {
            submenu: "dictionaries",
          },
        },
        {
          path: "product-bowl-positions",
          name: "dictionariesProductBowlPositions",
          component: DictionariesProductBowlPositions,
          meta: {
            submenu: "dictionaries",
          },
        },
        {
          path: "product-materials",
          name: "dictionariesProductMaterials",
          component: DictionariesProductMaterials,
          meta: {
            submenu: "dictionaries",
          },
        },
        {
          path: "product-shapes",
          name: "dictionariesProductShapes",
          component: DictionariesProductShapes,
          meta: {
            submenu: "dictionaries",
          },
        },
        {
          path: "product-structures",
          name: "dictionariesProductStructures",
          component: DictionariesProductStructures,
          meta: {
            submenu: "dictionaries",
          },
        },
        {
          path: "product-warranties",
          name: "dictionariesProductWarranties",
          component: DictionariesProductWarranties,
          meta: {
            submenu: "dictionaries",
          },
        },
        {
          path: "product-attributes",
          name: "dictionariesProductAttributes",
          component: DictionariesProductAttributes,
          meta: {
            submenu: "dictionaries",
          },
        },
        {
          path: "product-surfaces",
          name: "dictionariesProductSurfaces",
          component: DictionariesProductSurfaces,
          meta: {
            submenu: "dictionaries",
          },
        },
        {
          path: "product-technologies",
          name: "dictionariesProductTechnologies",
          component: DictionariesProductTechnologies,
          meta: {
            submenu: "dictionaries",
          },
        },
      ],
    },
    {
      path: "/files",
      name: "files",
      component: Files,
      meta: {
        submenu: "files",
      },
    },
    {
      path: "/files/upload",
      name: "filesUpload",
      component: FilesUpload,
      meta: {
        submenu: "files",
      },
    },
    {
      path: "file-types",
      name: "fileTypes",
      component: DictionariesFileTypes,
      meta: {
        submenu: "files",
      },
    },
    {
      path: "languages",
      name: "languages",
      component: Languages,
      meta: {
        submenu: "languages",
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  // try to access a restricted page + not logged in
  const publicPages = ["/login", "/reset-password"];
  const authRequired = !publicPages.includes(to.path);
  const user = localStorage.getItem("user");

  if (authRequired && !user) {
    return next("/login");
  } else if (user) {
    if (to.meta.adminAuth) {
      let userJson = JSON.parse(user);

      if (userJson.role != "admin") {
        return;
      }
    }

    // languages
    if (
      !store.state.language.all ||
      (store.state.language.all && !store.state.language.all.length)
    ) {
      store.dispatch("language/fetchLanguages");
    }

    // Change lang form param
    if (
      to.params.lang &&
      store.state.language.current &&
      to.params.lang != store.state.language.current
    ) {
      store
        .dispatch("language/setLanguage", {
          code: to.params.lang,
          preventReload: true,
        })
        .then(() => {
          return next();
        });
    } else if (!store.state.language.current) {
      store
        .dispatch("language/setLanguage", {
          code: "pl",
          preventReload: true,
        })
        .then(() => {
          return next();
        });
    } else {
      return next();
    }
  } else {
    return next();
  }
});
