<template>
  <div>
    <div class="content-top row" v-if="!isDeleted">
      <div class="col-24 content-top__wrap">
        <div class="content-top__title-box">
          <h1 class="content-top__heading">Edytuj produkt</h1>
          <div class="content-top__subheading" v-if="product.activity">
            ostatnia zmiana
            {{ product.activity.updated_at | prettyDate(true) }} przez
            {{ product.activity.user.email }}
          </div>
        </div>

        <div class="js-products-langs content-top__subwrap">
          <div
            class="d-flex justify-content-end language-switcher language-switcher--product"
            v-if="languages && product.languages"
          >
            <div
              class="language-switcher__lang"
              :class="[
                {
                  'is-active': $store.state.language.current == lang.code,
                },
              ]"
              v-for="lang in languages"
              :key="'lang_' + lang.code"
            >
              <div
                class="language-switcher__link"
                :class="[
                  product.languages.includes(lang.code)
                    ? 'language-switcher__link--added'
                    : 'language-switcher__link--no-added',
                ]"
                @click="switchLang(lang)"
              >
                {{ lang.code | uppercase }}
              </div>
            </div>
          </div>

          <div class="content-top__buttons">
            <button
              class="button button--icon"
              @click.prevent="saveProduct()"
              :disabled="isStoring || !changed"
            >
              <div
                class="loader button__icon button__icon--left"
                v-if="isStoring"
              ></div>
              <icon
                v-else
                icon="save"
                icon-classes="button__icon button__icon--left icon--white"
              />
              <span
                class="button__text"
                v-text="isStoring ? 'Zapisuję...' : 'Zapisz'"
                >Zapisz</span
              >
            </button>
            <button
              v-if="product"
              :class="[
                'button ml-2 button--icon button--' +
                  (!chekIfCanBeSync() || canBeSaved ? 'grey' : 'green'),
              ]"
              @click.prevent="syncProduct()"
              :disabled="!chekIfCanBeSync() || isSynchronized || canBeSaved"
            >
              <icon
                icon="export"
                icon-classes="button__icon button__icon--left icon--white"
              />
              <span
                class="button__text"
                v-text="isSynchronized ? 'Synchronizuję...' : 'Synchronizuj'"
                >Synchronizuj</span
              >
            </button>
            <div class="button--pl-22 mr-2 row">
              <span>Status synchronizacji</span>
              <div
                :class="[
                  'button__status',
                  ' ml-2',
                  'button__status--' + wooSyncStatus(product),
                ]"
              ></div>
            </div>
            <button
              class="button-icon button-icon--ml-10"
              @click.prevent="deleteProduct(product)"
            >
              <icon icon="trash" />
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="content" v-if="!isDeleted">
      <div class="row">
        <div class="col-24 col-xl-19">
          <div class="row">
            <div class="col-12">
              <ProductPhotos
                :product="product"
                photoType="main_photo"
                name="Zdjęcie główne"
                :multiplePhotos="false"
                @storingProduct="storingProduct"
                @onChangeee="handleOnPhotoUpload"
              ></ProductPhotos>
            </div>
            <div class="col-12">
              <ProductPhotos
                :product="product"
                photoType="variant_photo"
                name="Zdjęcie wariantu"
                :multiplePhotos="false"
                @storingProduct="storingProduct"
                @onChangeee="handleOnPhotoUpload"
              ></ProductPhotos>
            </div>
            <div class="col-24">
              <ProductPhotos
                :product="product"
                photoType="gallery"
                name="Galeria"
                column="6"
                :multiplePhotos="true"
                @storingProduct="storingProduct"
                @onChangeee="handleOnPhotoUpload"
              ></ProductPhotos>
            </div>
            <div class="col-24">
              <BasicInfo
                :product="product"
                @storingProduct="storingProduct"
                @updateProduct="updateProduct"
                @productChanged="productChanged"
              ></BasicInfo>
            </div>

            <div class="col-24">
              <ConnectFiles
                :product="product"
                @storingProduct="storingProduct"
              ></ConnectFiles>
            </div>

            <div class="col-24">
              <ConnectProducts
                :product="product"
                @storingProduct="storingProduct"
                type="products_variants"
                title="Warianty"
              ></ConnectProducts>
            </div>

            <div class="col-24">
              <ConnectProducts
                :product="product"
                @storingProduct="storingProduct"
                type="products_complementary"
                title="Produkty do kompletu"
              ></ConnectProducts>
            </div>

            <div class="col-24">
              <ConnectProducts
                :product="product"
                @storingProduct="storingProduct"
                type="products_other_sizes"
                title="Inne rozmiary produktu"
              ></ConnectProducts>
            </div>

            <div class="col-24">
              <ConnectProducts
                :product="product"
                category="4"
                @storingProduct="storingProduct"
                type="products_accessories"
                title="Akcesoria do produktu"
              ></ConnectProducts>
            </div>
          </div>
        </div>

        <div class="d-none d-xl-block col-xl-4 offset-xl-1">
          <div class="sidebar">
            <template v-if="languages">
              <div class="product-form__title">Wybierz język</div>
              <treeselect
                :multiple="false"
                :options="languages"
                @select="handleSelectLang"
                :value="selectedLang"
                value-format="object"
                :normalizer="normalizer"
                :clearable="false"
                placeholder=""
              />
              <hr />
            </template>
            <button
              class="button button--icon w-100"
              @click.prevent="saveProduct()"
              :disabled="isStoring || !changed"
            >
              <div
                class="loader button__icon button__icon--left"
                v-if="isStoring"
              ></div>
              <icon
                v-else
                icon="save"
                icon-classes="button__icon button__icon--left icon--white"
              />
              <span
                class="button__text"
                v-text="isStoring ? 'Zapisuję...' : 'Zapisz'"
                >Zapisz</span
              >
            </button>
            <button
              v-if="product"
              :class="[
                'button w-100 mt-2 button--icon button--' +
                  (!chekIfCanBeSync() || canBeSaved ? 'grey' : 'green'),
              ]"
              @click.prevent="syncProduct()"
              :disabled="!chekIfCanBeSync() || isSynchronized || canBeSaved"
            >
              <icon
                icon="export"
                icon-classes="button__icon button__icon--left icon--white"
              />
              <span
                class="button__text"
                v-text="isSynchronized ? 'Synchronizuję...' : 'Synchronizuj'"
                >Synchronizuj</span
              >
            </button>
            <div class="button--pl-22 mt-2 row">
              <span>Status synchronizacji</span>
              <div
                :class="[
                  'button__status',
                  ' ml-2',
                  'button__status--' + wooSyncStatus(product),
                ]"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="isDeleted">
      Trwa usuwanie produktu... Za chwilę zostaniesz przekierowany na listę
      produktów.
    </div>

    <!-- Modal delete product -->
    <modal
      :show="showModal"
      size="md"
      @close="showModal = !showModal"
      :redirect="redirect"
    >
      <div slot="header" class="notice-modal__header--title">
        Usuwanie produktu
      </div>

      <div slot="body">
        <div>
          Czy na pewno chcesz usunąć produkt
          <strong>{{
            productToDelete.index + " - " + productToDelete.name
          }}</strong
          >?
        </div>
      </div>

      <div slot="footer" class="d-flex align-items-center">
        <button
          class="button button--small button--grey"
          @click="showModal = false"
        >
          Nie
        </button>
        <button
          class="button button--small button--ml-10"
          @click="confirmDeleteProduct()"
        >
          Tak
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import axios from "axios";
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
import BasicInfo from "./product-modules/BasicInfo.vue";
import ProductPhotos from "./product-modules/ProductPhotos.vue";
import ConnectProducts from "./product-modules/ConnectProducts.vue";
import ConnectFiles from "./product-modules/ConnectFiles.vue";
import Icon from "./parts/Icon";
import Modal from "./parts/Modal";
import jQuery from "jquery";
import Treeselect from "@riophae/vue-treeselect";
import { wooSyncStatus } from "../helpers/statusWoo";

export default {
  data() {
    return {
      isSynchronized: false,
      product: {},
      isLoading: true,
      scrolled: false,
      changed: false,
      isStoring: false,
      productToDelete: {},
      languages: this.$store.state.language.all,
      showModal: false,
      redirect: {},
      isDeleted: false,
      selectedLang: {
        code: this.$store.state.language.current,
      },
      normalizer(node) {
        return {
          id: node.code,
          label: node.code.toUpperCase(),
        };
      },
    };
  },
  components: {
    ProductPhotos,
    ConnectProducts,
    ConnectFiles,
    BasicInfo,
    Icon,
    Modal,
    Treeselect,
  },
  created() {
    this.getProduct();
  },
  mounted() {
    let boxLang = jQuery(".js-products-langs"),
      sidebar = jQuery(".sidebar");

    if (boxLang.length != 0) {
      let topBoxLang = boxLang.offset().top + 125;

      var scroll = jQuery(window).scrollTop();
      if (scroll > topBoxLang) {
        sidebar.addClass("sidebar--open");
      } else {
        sidebar.removeClass("sidebar--open");
      }

      jQuery(window).scroll(function () {
        var scroll = jQuery(window).scrollTop();

        if (scroll > topBoxLang) {
          sidebar.addClass("sidebar--open");
        } else {
          sidebar.removeClass("sidebar--open");
        }
      });
    }
  },
  beforeDestroy() {
    this.$root.$off("addProductModalSuccess");
  },
  methods: {
    handleOnPhotoUpload() {
      this.product.woo_updated = 0;
    },
    chekIfCanBeSync() {
      return this.product.woo_updated == 0;
    },
    async syncProduct() {
      this.isSynchronized = true;
      var data = {
        product_id: this.product.id,
      };
      axios
        .post(API_BASE_URL + "/synchronize-woo", data)
        .then(() => {
          this.$root.$emit("noticesBox", {
            type: "success",
            message: "Poprawnie zsynchronizowano dane w wooCommerce.",
          });
          this.isSynchronized = false;
          this.getProduct();
        })
        .catch((error) => {
          this.isSynchronized = false;
          this.$root.$emit("noticesBox", error.response.data);
          this.getProduct();
        });
    },
    wooSyncStatus,
    getProduct(refresh = false) {
      let vm = this;
      axios
        .get(API_BASE_URL + "/products/" + vm.$route.params.id)
        .then((response) => {
          vm.product = response.data;

          if (
            !refresh &&
            vm.$store.state.language.current != response.data.language.code
          ) {
            vm.$store.dispatch("language/setLanguage", response.data.language);
          }
        })
        .catch((error) => {
          vm.$root.$emit("noticesBox", error.response.data);
        });
      this.isLoading = false;
    },
    saveProduct() {
      this.$root.$emit("saveProduct");
      this.isStoring = true;
    },
    updateProduct(product) {
      this.product = product;
      this.isStoring = false;
    },
    productChanged(changed) {
      this.changed = changed;
    },
    storingProduct(status) {
      this.isStoring = status;
    },
    async deleteProduct(product) {
      this.showModal = true;
      this.productToDelete = product;
    },
    confirmDeleteProduct() {
      let vm = this;
      this.showModal = false;
      axios
        .delete(API_BASE_URL + "/products/" + vm.productToDelete.id)
        .then(() => {
          vm.isDeleted = setTimeout(() => {
            vm.$router.push({
              name: "productsList",
            });
          }, 2000);
        });
    },
    switchLang(lang) {
      let vm = this;

      vm.$store
        .dispatch("language/setLanguage", {
          code: lang.code,
          preventReload: true,
        })
        .then(function () {
          vm.$router.push({
            name: "productEdit",
            params: {
              id: vm.product.id,
              lang: lang.code,
            },
          });
        });
    },
    handleSelectLang(node) {
      this.selectedLang = {
        code: this.$store.state.language.current,
      };

      this.switchLang(node);
    },
  },
};
</script>
