<template>
  <div>
    <div class="product-form row">
      <div class="w-100" v-if="isLoaded">
        <div class="col-24" v-if="$props.formType != 'add'">
          <div class="product-form__header d-flex align-items-center">
            <div
              :class="{ 'is-hidden': isHidden }"
              @click.prevent="isHidden = !isHidden"
            >
              <icon
                icon="plus"
                v-if="!isHidden"
                icon-classes="icon__plus--large icon--black icon--rotated"
              />
              <icon
                icon="plus"
                v-else
                icon-classes="icon__plus--large icon--black"
              />
            </div>
            <span class="product-form__header-title"
              >Informacje podstawowe</span
            >
          </div>
        </div>

        <transition name="transition-slide">
          <div class="col-24" v-if="!isHidden">
            <div class="row">
              <div class="col-24 col-xl">
                <div class="product-form__input-wrap">
                  <div class="product-form__title">Nazwa</div>
                  <div class="d-flex align-items-center">
                    <input
                      class="product-form__input"
                      type="text"
                      v-model="localProduct.name"
                      @change="onInputChange()"
                    />
                    <button
                      class="button-icon button-icon--big button-icon--ml-10"
                      @click="showModal = !showModal"
                    >
                      <icon icon="languages-small" />
                    </button>
                  </div>
                </div>
              </div>

              <div class="col-24 col-xl-6">
                <div class="product-form__input-wrap">
                  <div class="product-form__title">Kod klienta</div>
                  <input
                    class="product-form__input"
                    type="text"
                    v-model="localProduct.ktm"
                    @change="onInputChange()"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-24 col-xl">
                <div class="product-form__input-wrap">
                  <div class="product-form__title">Link produktu</div>
                  <div class="d-flex align-items-center">
                    <button
                      class="button button--icon mb-3"
                      @click="copyToClipBoard(localProduct.woo_url)"
                    >
                      {{ localProduct.woo_url }}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-24 col-xl">
                <div class="product-form__input-wrap">
                  <div class="product-form__title">Kod modelu 3D</div>
                  <div class="d-flex align-items-center">
                    <textarea
                      class="product-form__input product-form__input--textarea"
                      v-model="localProduct.model_3d_code"
                      @change="onInputChange()"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-24 col-xl">
                <div class="product-form__input-wrap">
                  <div class="product-form__title">Film</div>
                  <div class="d-flex align-items-center">
                    <input
                      class="product-form__input"
                      type="text"
                      v-model="localProduct.movie_url"
                      @change="onInputChange()"
                    />
                  </div>
                  <div class="d-flex align-items-center mt-2">
                    <input
                      class="product-form__input"
                      type="text"
                      v-model="localProduct.movie_url_2"
                      @change="onInputChange()"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-24 col-xl">
                <div class="product-form__input-wrap">
                  <div class="product-form__title">Status</div>
                  <label
                    :for="'check-status-' + localProduct.id"
                    class="product-form__checkbox"
                  >
                    <input
                      :id="'check-status-' + localProduct.id"
                      class="product-form__checkbox--input"
                      type="checkbox"
                      v-model="localProduct.is_active"
                      @change="onInputChange()"
                    />
                    <span
                      class="product-form__checkbox-checkmark"
                      v-text="localProduct.is_active ? 'Aktywny' : 'Nieaktywny'"
                    ></span>
                  </label>
                </div>
              </div>

              <div class="col-24 col-xl">
                <div class="product-form__input-wrap">
                  <div class="product-form__title">Nowy produkt</div>
                  <label
                    :for="'check-new-' + localProduct.id"
                    class="product-form__checkbox"
                  >
                    <input
                      :id="'check-new-' + localProduct.id"
                      class="product-form__checkbox--input"
                      type="checkbox"
                      v-model="localProduct.is_new"
                      @change="onInputChange()"
                    />
                    <span
                      class="product-form__checkbox-checkmark"
                      v-text="localProduct.is_new ? 'Tak' : 'Nie'"
                    ></span>
                  </label>
                </div>
              </div>

              <div class="col-24 col-xl">
                <div class="product-form__input-wrap">
                  <div class="product-form__title">Produkt główny</div>
                  <label
                    :for="'check-root-' + localProduct.id"
                    class="product-form__checkbox"
                  >
                    <input
                      :id="'check-root-' + localProduct.id"
                      class="product-form__checkbox--input"
                      type="checkbox"
                      v-model="localProduct.is_root"
                      @change="onInputChange()"
                    />
                    <span
                      class="product-form__checkbox-checkmark"
                      v-text="localProduct.is_root ? 'Tak' : 'Nie'"
                    ></span>
                  </label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-24 col-xl-24">
                <div class="product-form__input-wrap">
                  <div class="product-form__title">Krótki opis</div>
                  <textarea
                    class="product-form__input product-form__input--textarea"
                    @change="onInputChange()"
                    v-model="localProduct.description_short"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-24 col-xl-24">
                <div class="product-form__input-wrap">
                  <div class="product-form__title">Opis</div>
                  <textarea
                    class="product-form__input product-form__input--textarea"
                    @change="onInputChange()"
                    v-model="localProduct.description"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-24 col-xl-8">
                <div class="product-form__input-wrap">
                  <div class="product-form__title">Index</div>
                  <input
                    class="product-form__input"
                    type="text"
                    v-model="localProduct.index"
                    @change="onInputChange()"
                  />
                </div>
              </div>
              <div class="col-24 col-xl-8">
                <div class="product-form__input-wrap">
                  <div class="product-form__title">EAN</div>
                  <input
                    class="product-form__input"
                    type="text"
                    v-model="localProduct.ean"
                    @change="onInputChange()"
                  />
                </div>
              </div>

              <div class="col-24 col-xl-8">
                <div class="product-form__input-wrap">
                  <div class="product-form__title">Region</div>
                  <treeselect
                    v-if="regions.length > 0"
                    v-model="localProduct.regions"
                    :multiple="true"
                    :options="regions"
                    placeholder=""
                    :normalizer="normalizerMultiple"
                    :clearable="false"
                    :limit="3"
                    :limitText="limitText"
                    @input="onInputChange()"
                  />
                  <div
                    v-else
                    class="product-form__note"
                    v-text="
                      isLoading
                        ? 'Trwa wczytywanie...'
                        : 'Brak zdefiniowanych regionów w bazie.'
                    "
                  ></div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-24 col-xl">
                <div class="product-form__input-wrap">
                  <div class="product-form__title">Brand</div>
                  <treeselect
                    v-if="brands.length > 0"
                    v-model="localProduct.brand_id"
                    :multiple="false"
                    :options="brands"
                    placeholder=""
                    :normalizer="normalizer"
                    :clearable="false"
                    @input="onBrandChange()"
                  />
                  <div
                    v-else
                    class="product-form__note"
                    v-text="
                      isLoading
                        ? 'Trwa wczytywanie...'
                        : 'Brak zdefiniowanych brandów w bazie.'
                    "
                  ></div>
                </div>
              </div>

              <div class="col-24 col-xl">
                <div class="product-form__input-wrap">
                  <div class="product-form__title">Kategoria</div>
                  <treeselect
                    v-if="categories.length > 0"
                    v-model="localProduct.category_id"
                    :multiple="false"
                    :options="categories"
                    placeholder=""
                    :normalizer="normalizer"
                    :clearable="false"
                    :default-expand-level="Infinity"
                    @input="onCategoryChange()"
                  />
                  <div
                    v-else
                    class="product-form__note"
                    v-text="
                      isLoading
                        ? 'Trwa wczytywanie...'
                        : localProduct.brand_id
                        ? 'Brak zdefiniowanych kategorii w bazie.'
                        : 'Należy najpierw wybrać brand.'
                    "
                  ></div>
                </div>
              </div>

              <div class="col-24 col-xl">
                <div class="product-form__input-wrap">
                  <div class="product-form__title">Średnica odpływu</div>
                  <treeselect
                    v-if="outflow_diameters.length > 0"
                    v-model="localProduct.outflow_diameter_id"
                    :multiple="false"
                    :options="outflow_diameters"
                    placeholder=""
                    :normalizer="normalizerUnit"
                    :clearable="false"
                    @input="onInputChange()"
                  />
                  <div
                    v-else
                    class="product-form__note"
                    v-text="
                      isLoading
                        ? 'Trwa wczytywanie...'
                        : 'Brak zdefiniowanych średnic odpływu w bazie.'
                    "
                  ></div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-24 col-xl">
                <div class="product-form__input-wrap">
                  <div class="product-form__title">Overflow</div>
                  <treeselect
                    v-if="overflows.length > 0"
                    v-model="localProduct.overflows"
                    :multiple="true"
                    :options="overflows"
                    placeholder=""
                    :normalizer="normalizerMultiple"
                    :clearable="false"
                    :limit="3"
                    :limitText="limitText"
                    @input="onInputChange()"
                  />
                  <div
                    v-else
                    class="product-form__note"
                    v-text="
                      isLoading
                        ? 'Trwa wczytywanie...'
                        : 'Brak zdefiniowanych overflow w bazie.'
                    "
                  ></div>
                </div>
              </div>
              <div class="col-24 col-xl">
                <div class="product-form__input-wrap">
                  <div class="product-form__title">Pozycja miski</div>
                  <treeselect
                    v-if="bowlPositions.length > 0"
                    v-model="localProduct.bowl_positions"
                    :multiple="true"
                    :options="bowlPositions"
                    placeholder=""
                    :normalizer="normalizerMultiple"
                    :clearable="false"
                    :limit="3"
                    :limitText="limitText"
                    @input="onInputChange()"
                  />
                  <div
                    v-else
                    class="product-form__note"
                    v-text="
                      isLoading
                        ? 'Trwa wczytywanie...'
                        : 'Brak zdefiniowanych pozycji miski w bazie.'
                    "
                  ></div>
                </div>
              </div>

              <div class="col-24 col-xl">
                <div class="product-form__input-wrap">
                  <div class="product-form__title">Kolor</div>
                  <treeselect
                    v-if="colors.length > 0"
                    v-model="localProduct.colors"
                    :multiple="true"
                    :options="colors"
                    placeholder=""
                    :normalizer="normalizerMultiple"
                    :clearable="false"
                    :limit="3"
                    :limitText="limitText"
                    @input="onInputChange()"
                  />
                  <div
                    v-else
                    class="product-form__note"
                    v-text="
                      isLoading
                        ? 'Trwa wczytywanie...'
                        : 'Brak zdefiniowanych kolorów w bazie.'
                    "
                  ></div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-24 col-xl">
                <div class="product-form__input-wrap">
                  <div class="product-form__title">Instalacja</div>
                  <treeselect
                    v-if="installations.length > 0 && localProduct.category_id"
                    v-model="localProduct.installations"
                    :multiple="true"
                    :options="installations"
                    placeholder=""
                    :normalizer="normalizerMultiple"
                    :clearable="false"
                    :limit="3"
                    :limitText="limitText"
                    @input="onInputChange(), changeInstallations()"
                  />
                  <div
                    v-else-if="!localProduct.category_id"
                    class="product-form__note"
                    v-text="
                      isLoading
                        ? 'Trwa wczytywanie...'
                        : 'Wybierz kategorię aby wybrać instalację'
                    "
                  ></div>
                  <div
                    v-else
                    class="product-form__note"
                    v-text="
                      isLoading
                        ? 'Trwa wczytywanie...'
                        : 'Brak zdefiniowanych instalacji w bazie.'
                    "
                  ></div>
                </div>
              </div>

              <div class="col-24 col-xl">
                <div class="product-form__input-wrap">
                  <div class="product-form__title">Materiał</div>
                  <treeselect
                    v-if="materials.length > 0"
                    v-model="localProduct.materials"
                    :multiple="true"
                    :options="materials"
                    placeholder=""
                    :normalizer="normalizerMultiple"
                    :clearable="false"
                    :limit="3"
                    :limitText="limitText"
                    @input="onInputChange()"
                  />
                  <div
                    v-else
                    class="product-form__note"
                    v-text="
                      isLoading
                        ? 'Trwa wczytywanie...'
                        : 'Brak zdefiniowanych materiałów w bazie.'
                    "
                  ></div>
                </div>
              </div>
              <div class="col-24 col-xl">
                <div class="product-form__input-wrap">
                  <div class="product-form__title">Kształt</div>
                  <treeselect
                    v-if="shapes.length > 0"
                    v-model="localProduct.shapes"
                    :multiple="true"
                    :options="shapes"
                    placeholder=""
                    :normalizer="normalizerMultiple"
                    :clearable="false"
                    :limit="3"
                    :limitText="limitText"
                    @input="onInputChange()"
                  />
                  <div
                    v-else
                    class="product-form__note"
                    v-text="
                      isLoading
                        ? 'Trwa wczytywanie...'
                        : 'Brak zdefiniowanych kształtów w bazie.'
                    "
                  ></div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-24 col-xl">
                <div class="product-form__input-wrap">
                  <div class="product-form__title">Struktura</div>
                  <treeselect
                    v-if="structures.length > 0"
                    v-model="localProduct.structures"
                    :multiple="true"
                    :options="structures"
                    placeholder=""
                    :normalizer="normalizerMultiple"
                    :clearable="false"
                    :limit="3"
                    :limitText="limitText"
                    @input="onInputChange()"
                  />
                  <div
                    v-else
                    class="product-form__note"
                    v-text="
                      isLoading
                        ? 'Trwa wczytywanie...'
                        : 'Brak zdefiniowanych struktur w bazie.'
                    "
                  ></div>
                </div>
              </div>

              <div class="col-24 col-xl">
                <div class="product-form__input-wrap">
                  <div class="product-form__title">Powierzchnia</div>
                  <treeselect
                    v-if="surfaces.length > 0"
                    v-model="localProduct.surfaces"
                    :multiple="true"
                    :options="surfaces"
                    placeholder=""
                    :normalizer="normalizerMultiple"
                    :clearable="false"
                    :limit="3"
                    :limitText="limitText"
                    @input="onInputChange()"
                  />
                  <div
                    v-else
                    class="product-form__note"
                    v-text="
                      isLoading
                        ? 'Trwa wczytywanie...'
                        : 'Brak zdefiniowanych powierzchni w bazie.'
                    "
                  ></div>
                </div>
              </div>

              <div class="col-24 col-xl">
                <div class="product-form__input-wrap">
                  <div class="product-form__title">Technologia</div>
                  <treeselect
                    v-if="technologies.length > 0"
                    v-model="localProduct.technologies"
                    :multiple="true"
                    :options="technologies"
                    placeholder=""
                    :normalizer="normalizerMultiple"
                    :clearable="false"
                    :limit="3"
                    :limitText="limitText"
                    @input="onInputChange()"
                  />
                  <div
                    v-else
                    class="product-form__note"
                    v-text="
                      isLoading
                        ? 'Trwa wczytywanie...'
                        : 'Brak zdefiniowanych technologii w bazie.'
                    "
                  ></div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-24 col-xl">
                <div class="product-form__input-wrap">
                  <div class="product-form__title">Gwarancja</div>
                  <treeselect
                    v-if="warranties.length > 0"
                    v-model="localProduct.warranty_id"
                    :multiple="false"
                    :options="warranties"
                    placeholder=""
                    :normalizer="normalizerMultiple"
                    :clearable="false"
                    :limit="3"
                    :limitText="limitText"
                    @input="onInputChange()"
                  />
                  <div
                    v-else
                    class="product-form__note"
                    v-text="
                      isLoading
                        ? 'Trwa wczytywanie...'
                        : 'Brak zdefiniowanych gwarancji w bazie.'
                    "
                  ></div>
                </div>
              </div>
              <div class="col-24 col-xl">
                <div class="product-form__input-wrap">
                  <div class="product-form__title">Kategoria brodzika</div>
                  <treeselect
                    v-if="showertray_categories.length > 0"
                    v-model="localProduct.showertray_category_id"
                    :multiple="false"
                    :options="showertray_categories"
                    placeholder=""
                    :normalizer="normalizer"
                    :clearable="false"
                    :default-expand-level="Infinity"
                    @input="onInputChange()"
                  />
                  <div
                    v-else
                    class="product-form__note"
                    v-text="
                      isLoading
                        ? 'Trwa wczytywanie...'
                        : 'Brak zdefiniowanych gwarancji w bazie.'
                    "
                  ></div>
                </div>
              </div>

              <div class="col-24 col-xl">
                <div class="product-form__input-wrap">
                  <div class="product-form__title">Mineral Composite Cover</div>
                  <label
                    :for="'check-dolomite-cover-' + localProduct.id"
                    class="product-form__checkbox"
                  >
                    <input
                      :id="'check-dolomite-cover-' + localProduct.id"
                      class="product-form__checkbox--input"
                      type="checkbox"
                      v-model="localProduct.dolomite_cover"
                      @change="onInputChange()"
                    />
                    <span
                      class="product-form__checkbox-checkmark"
                      v-text="localProduct.dolomite_cover ? 'Tak' : 'Nie'"
                    ></span>
                  </label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-24 col-xl">
                <div class="product-form__input-wrap">
                  <div class="product-form__title">Waste included</div>
                  <label
                    :for="'check-is-waste-included-' + localProduct.id"
                    class="product-form__checkbox"
                  >
                    <input
                      :id="'check-is-waste-included-' + localProduct.id"
                      class="product-form__checkbox--input"
                      type="checkbox"
                      v-model="localProduct.is_waste_included"
                      @change="onInputChange()"
                    />
                    <span
                      class="product-form__checkbox-checkmark"
                      v-text="localProduct.is_waste_included ? 'Tak' : 'Nie'"
                    ></span>
                  </label>
                </div>
              </div>

              <div class="col-24 col-xl">
                <div class="product-form__input-wrap">
                  <div class="product-form__title">Tekstura</div>
                  <label
                    :for="'check-is-texture-' + localProduct.id"
                    class="product-form__checkbox"
                  >
                    <input
                      :id="'check-is-texture-' + localProduct.id"
                      class="product-form__checkbox--input"
                      type="checkbox"
                      v-model="localProduct.is_texture"
                      @change="onInputChange()"
                    />
                    <span
                      class="product-form__checkbox-checkmark"
                      v-text="localProduct.is_texture ? 'Tak' : 'Nie'"
                    ></span>
                  </label>
                </div>
              </div>

              <div class="col-24 col-xl">
                <div class="product-form__input-wrap">
                  <div class="product-form__title">Syfon</div>
                  <label
                    :for="'check-is-siphon-' + localProduct.id"
                    class="product-form__checkbox"
                  >
                    <input
                      :id="'check-is-siphon-' + localProduct.id"
                      class="product-form__checkbox--input"
                      type="checkbox"
                      v-model="localProduct.is_siphon"
                      @change="onInputChange()"
                    />
                    <span
                      class="product-form__checkbox-checkmark"
                      v-text="localProduct.is_siphon ? 'Tak' : 'Nie'"
                    ></span>
                  </label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-24 col-xl">
                <div class="product-form__input-wrap">
                  <div class="product-form__title">Szerokość</div>
                  <input
                    class="product-form__input"
                    type="text"
                    v-model="localProduct.width"
                    @change="onInputChange()"
                  />
                </div>
              </div>

              <div class="col-24 col-xl">
                <div class="product-form__input-wrap">
                  <div class="product-form__title">Wysokość</div>
                  <input
                    class="product-form__input"
                    type="text"
                    v-model="localProduct.height"
                    @change="onInputChange()"
                  />
                </div>
              </div>

              <div class="col-24 col-xl">
                <div class="product-form__input-wrap">
                  <div class="product-form__title">Długość</div>
                  <input
                    class="product-form__input"
                    type="text"
                    v-model="localProduct.length"
                    @change="onInputChange()"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-24 col-xl">
                <div class="product-form__input-wrap">
                  <div class="product-form__title">Objętość</div>
                  <input
                    class="product-form__input"
                    type="text"
                    v-model="localProduct.volume"
                    @change="onInputChange()"
                  />
                </div>
              </div>

              <div class="col-24 col-xl">
                <div class="product-form__input-wrap">
                  <div class="product-form__title">Waga (netto)</div>
                  <input
                    class="product-form__input"
                    type="text"
                    v-model="localProduct.weight_netto"
                    @change="onInputChange()"
                  />
                </div>
              </div>

              <div class="col-24 col-xl">
                <div class="product-form__input-wrap">
                  <div class="product-form__title">Waga (brutto)</div>
                  <input
                    class="product-form__input"
                    type="text"
                    v-model="localProduct.weight_brutto"
                    @change="onInputChange()"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-24 col-xl">
                <div class="product-form__input-wrap">
                  <div class="product-form__title">Ilość na palecie</div>
                  <input
                    class="product-form__input"
                    type="text"
                    v-model="localProduct.per_pallet"
                    @change="onInputChange()"
                  />
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
      <div v-else>
        Pobieranie podstawowych informacji o produkcie...
      </div>
    </div>

    <!-- Modal -->
    <modal
      :show="showModal"
      @close="showModal = !showModal"
      v-if="product.names"
    >
      <div
        slot="header"
        class="d-flex flex-column flex-xl-row justify-content-xl-between align-items-xl-center w-100"
      >
        <div>Wprowadź tłumaczenia nazwy produktu</div>
      </div>

      <div slot="body">
        <template v-for="lang in languages">
          <div
            class="product-form__input-wrap mb-3"
            :key="'lang_' + lang.code"
            v-if="lang.code != $store.state.language.current"
          >
            <div class="product-form__title">{{ lang.code }}</div>
            <input
              class="product-form__input"
              type="text"
              @change="onInputChange()"
              v-model="localProduct.names[lang.code]"
            />
          </div>
        </template>
      </div>

      <div slot="footer" class="d-flex align-items-center">
        <button
          class="button button--small button--grey"
          @click="cancelChangeNames()"
        >
          Anuluj
        </button>
        <button
          class="button button--small button--ml-10"
          @click="storeProduct()"
          :disabled="isStoring || !changed"
        >
          Zapisz
        </button>
      </div>
    </modal>
  </div>
</template>
<script>
import axios from "axios";
import Icon from "../parts/Icon";
import Modal from "./../parts/Modal";
import Treeselect from "@riophae/vue-treeselect";
import _ from "lodash";
import { changeObjectToArray } from "../../helpers/objectToArray.js";

const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

export default {
  data() {
    return {
      languages: this.$store.state.language.all,
      localProduct: {
        is_active: 0,
      },
      changed: false,
      bowlPositions: [],
      brands: [],
      categories: [],
      showertray_categories: [],
      warranties: [],
      colors: [],
      installations: [],
      overflows: [],
      materials: [],
      outflow_diameters: [],
      regions: [],
      shapes: [],
      structures: [],
      surfaces: [],
      technologies: [],
      isHidden: false,
      isLoaded: false,
      isLoading: true,
      isStoring: false,
      isFirstLoad: true,
      showModal: false,
      objectToDelete: false,
      normalizer(node) {
        return {
          id: node.id,
          label: "#" + node.id + " " + node.name,
          children: node.subcategories,
        };
      },
      normalizerUnit(node) {
        return {
          id: node.id,
          label: "#" + node.id + " " + node.name + " " + node.unit,
        };
      },
      normalizerMultiple(node) {
        return {
          id: node.id,
          label: "#" + node.id + " " + node.name,
        };
      },
      colorsToAdd: [
        2,
        3,
        12,
        16,
        17,
        19,
        26,
        28,
        31,
        34,
        42,
        44,
        47,
        66,
        67,
        68,
        69,
        70,
      ],
    };
  },
  components: {
    Icon,
    Modal,
    Treeselect,
  },
  props: {
    product: {
      type: Object,
      required: false,
    },
  },
  watch: {
    product: {
      handler(newVal) {
        this.localProduct = _.cloneDeep(newVal);

        // names
        if (this.localProduct.names && this.localProduct.names.length == 0) {
          this.localProduct.names = {};
        }

        // jeżeli to pierwsze załadowanie, to pobieramy selecty
        if (this.isFirstLoad) {
          this.getEditProductData();
        }

        // status
        this.localProduct.is_active =
          this.localProduct.is_active == "1" ? true : false;

        // bow positions
        let bowl_positions = [];
        this.localProduct.bowl_positions.forEach((obj) => {
          bowl_positions.push(obj.id);
        });
        this.localProduct.bowl_positions = bowl_positions;

        // colors
        let colors = [];
        this.localProduct.colors.forEach((obj) => {
          colors.push(obj.id);
        });
        this.localProduct.colors = colors;

        // installations
        let installations = [];
        this.localProduct.installations.forEach((obj) => {
          installations.push(obj.id);
        });
        this.localProduct.installations = installations;

        this.getInstallations();

        // overflows
        let overflows = [];
        this.localProduct.overflows.forEach((obj) => {
          overflows.push(obj.id);
        });
        this.localProduct.overflows = overflows;

        // materials
        let materials = [];
        this.localProduct.materials.forEach((obj) => {
          materials.push(obj.id);
        });
        this.localProduct.materials = materials;

        // regions
        let regions = [];
        this.localProduct.regions.forEach((obj) => {
          regions.push(obj.id);
        });
        this.localProduct.regions = regions;

        // shapes
        let shapes = [];
        this.localProduct.shapes.forEach((obj) => {
          shapes.push(obj.id);
        });
        this.localProduct.shapes = shapes;

        // structures
        let structures = [];
        this.localProduct.structures.forEach((obj) => {
          structures.push(obj.id);
        });
        this.localProduct.structures = structures;

        // surfaces
        let surfaces = [];
        this.localProduct.surfaces.forEach((obj) => {
          surfaces.push(obj.id);
        });
        this.localProduct.surfaces = surfaces;

        // technologies
        let technologies = [];
        this.localProduct.technologies.forEach((obj) => {
          technologies.push(obj.id);
        });
        this.localProduct.technologies = technologies;

        // załadowane
        this.isLoaded = true;
      },
      deep: true,
      immediate: false,
    },
  },
  mounted() {
    this.$root.$on("saveProduct", () => {
      this.storeProduct();
    });

    ///this.getProductLink();
  },
  beforeDestroy() {
    this.$root.$off("saveProduct");
    this.editor.destroy();
  },
  methods: {
    addColors() {
      var colorsIds = this.changeObjectToArray(this.colors);
      this.colorsToAdd = this.colorsToAdd.filter((value) =>
        colorsIds.includes(value)
      );
      this.localProduct.colors = this.localProduct.colors.concat(
        this.colorsToAdd
      );
      this.localProduct.colors = Array.from(new Set(this.localProduct.colors));
    },
    changeObjectToArray,
    changeInstallations() {
      if (this.localProduct.installations.includes(7)) {
        this.addColors();
      }
    },
    onInputChange() {
      if (this.changed == false) {
        this.$emit("productChanged", true);
        this.changed = true;
      }
    },
    onBrandChange() {
      this.getCategories();
      this.localProduct.category_id = null;
      this.onInputChange();
    },
    onCategoryChange() {
      this.localProduct.installations = [];
      this.getInstallations();
      this.onInputChange();
    },
    async storeProduct() {
      this.showModal = false;

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      this.$emit("storingProduct", true);

      let formData = new FormData();
      formData.append("id", this.localProduct.id);

      formData.append("name", this.localProduct.name);
      formData.append("model_3d_code", this.localProduct.model_3d_code);
      formData.append("movie_url", this.localProduct.movie_url);
      formData.append("movie_url_2", this.localProduct.movie_url_2);

      formData.append("names", JSON.stringify(this.localProduct.names));
      formData.append("ktm", this.localProduct.ktm);
      formData.append("description", this.localProduct.description);
      formData.append("description_short", this.localProduct.description_short);
      formData.append("index", this.localProduct.index);
      formData.append("ean", this.localProduct.ean);
      formData.append(
        "is_waste_included",
        this.localProduct.is_waste_included ? "1" : "0"
      );
      formData.append("is_texture", this.localProduct.is_texture ? "1" : "0");
      formData.append("is_siphon", this.localProduct.is_siphon ? "1" : "0");

      formData.append("is_active", this.localProduct.is_active ? "1" : "0");
      formData.append("is_root", this.localProduct.is_root ? "1" : "0");
      formData.append("is_new", this.localProduct.is_new ? "1" : "0");
      formData.append(
        "dolomite_cover",
        this.localProduct.dolomite_cover ? "1" : "0"
      );

      formData.append(
        "brand_id",
        this.localProduct.brand_id ? this.localProduct.brand_id : null
      );
      formData.append(
        "category_id",
        this.localProduct.category_id ? this.localProduct.category_id : null
      );
      formData.append(
        "showertray_category_id",
        this.localProduct.showertray_category_id
          ? this.localProduct.showertray_category_id
          : null
      );
      formData.append(
        "warranty_id",
        this.localProduct.warranty_id ? this.localProduct.warranty_id : null
      );
      formData.append(
        "outflow_diameter_id",
        this.localProduct.outflow_diameter_id
          ? this.localProduct.outflow_diameter_id
          : null
      );

      formData.append("bowl_positions", this.localProduct.bowl_positions);
      formData.append("colors", this.localProduct.colors);
      formData.append("installations", this.localProduct.installations);
      formData.append("overflows", this.localProduct.overflows);
      formData.append("materials", this.localProduct.materials);
      formData.append("regions", this.localProduct.regions);

      formData.append("shapes", this.localProduct.shapes);
      formData.append("structures", this.localProduct.structures);
      formData.append("surfaces", this.localProduct.surfaces);
      formData.append("technologies", this.localProduct.technologies);

      formData.append("width", this.localProduct.width);
      formData.append("height", this.localProduct.height);
      formData.append("length", this.localProduct.length);

      formData.append("volume", this.localProduct.volume);
      formData.append("weight_netto", this.localProduct.weight_netto);
      formData.append("weight_brutto", this.localProduct.weight_brutto);
      formData.append("per_pallet", this.localProduct.per_pallet);

      axios
        .post(API_BASE_URL + "/products", formData, config)
        .then((response) => {
          this.$root.$emit("noticesBox", {
            type: "success",
            message: "Poprawnie zapisano dane podstawowe produktu.",
          });
          this.$emit("updateProduct", response.data);
          this.$emit("productChanged", false);
          this.changed = false;
        })
        .catch((error) => {
          this.$emit("storingProduct", false);
          this.$root.$emit("noticesBox", error.response.data);
        });
    },
    getEditProductData() {
      this.isLoading = true;
      axios
        .get(API_BASE_URL + "/product-edit")
        .then((response) => {
          this.brands = response.data.brands;
          this.bowlPositions = response.data.bowl_positions;
          this.colors = response.data.colors;
          //this.installations = response.data.installations;
          this.overflows = response.data.overflows;
          this.materials = response.data.materials;
          this.outflow_diameters = response.data.outflow_diameters;
          this.regions = response.data.regions;
          this.shapes = response.data.shapes;
          this.structures = response.data.structures;
          this.surfaces = response.data.surfaces;
          this.technologies = response.data.technologies;
          this.warranties = response.data.warranties;
          this.showertray_categories = response.data.showertray_categories;
          if (this.brands) {
            this.brands.forEach((brand) => {
              if (brand.id == this.localProduct.brand_id) {
                if (brand.categories.length > 0) {
                  brand.categories.forEach((item) => {
                    if (item.subcategories.length == 0) {
                      item.subcategories = undefined;
                    } else {
                      this.changeEmptyArrayToUndefinedRecursive(
                        item.subcategories
                      );
                    }

                    this.categories = brand.categories;
                  });
                }
              }
            });
          }

          this.isLoading = false;
        })
        .catch((error) => {
          this.$root.$emit("noticesBox", error.response.data);
          this.isLoading = false;
        });

      this.isFirstLoad = false;
    },
    getCategories() {
      this.isLoading = true;
      axios
        .get(API_BASE_URL + "/product-categories/" + this.localProduct.brand_id)
        .then((response) => {
          response.data.forEach((item) => {
            if (item.subcategories.length == 0) {
              item.subcategories = undefined;
            } else {
              this.changeEmptyArrayToUndefinedRecursive(item.subcategories);
            }
          });

          this.isLoading = false;
          this.categories = response.data;
        })
        .catch((error) => {
          this.isLoading = false;
          this.$root.$emit("noticesBox", error.response.data);
        });
    },
    changeEmptyArrayToUndefinedRecursive(items) {
      let flat = [];

      items.forEach((item) => {
        if (
          Array.isArray(item.subcategories) &&
          item.subcategories.length > 0
        ) {
          flat.push(
            ...this.changeEmptyArrayToUndefinedRecursive(item.subcategories)
          );
        } else {
          item.subcategories = undefined;
          flat.push(item);
        }
      });

      return flat;
    },
    limitText(count) {
      return "+ " + count;
    },
    cancelChangeNames() {
      this.showModal = false;
      this.localProduct.names = _.cloneDeep(this.product.names);
    },
    copyToClipBoard(textToCopy) {
      navigator.clipboard.writeText(textToCopy);
      this.$root.$emit("noticesBox", {
        type: "success",
        message: "Skopiowano link",
      });
    },
    getInstallations() {
      let params = {
        category_id: this.localProduct.category_id,
      };

      axios
        .get(API_BASE_URL + "/product-installations", { params: params })
        .then((response) => {
          this.installations = response.data;
        })
        .catch((error) => {
          this.$root.$emit("noticesBox", error.response.data);
        });
    },
  },
};
</script>
