var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.localProduct)?_c('div',[_c('input',{ref:"photosInput",staticStyle:{"display":"none"},attrs:{"type":"file","disabled":_vm.isStoring},on:{"change":_vm.onImageChange}}),_c('div',{staticClass:"product-form__title"},[_vm._v(_vm._s(_vm.name))]),(
      Array.isArray(_vm.localProduct[_vm.photoType]) ||
      !_vm.localProduct[_vm.photoType] ||
      !Object.keys(_vm.localProduct[_vm.photoType]).length
    )?_c('button',{staticClass:"button button--icon mb-3",attrs:{"disabled":_vm.isStoring},on:{"click":function($event){return _vm.$refs.photosInput.click()}}},[(!_vm.isStoring)?_c('icon',{attrs:{"icon":"plus","icon-classes":"icon--white button__icon button__icon--left icon--white"}}):_c('div',{staticClass:"loader button__icon button__icon--left"}),_c('span',{staticClass:"button__text",domProps:{"textContent":_vm._s(_vm.isStoring ? 'Wysyłanie...' : 'Dodaj zdjęcie')}},[_vm._v(" Dodaj zdjęcie")])],1):_vm._e(),(Array.isArray(_vm.localProduct[_vm.photoType]))?_c('div',{staticClass:"row"},_vm._l((_vm.localProduct[_vm.photoType]),function(photo){return _c('div',{key:photo.id,class:'col-' + _vm.column},[_c('div',{staticClass:"product-photo"},[_c('img',{staticClass:"product-photo__img",attrs:{"src":_vm.getApiPath(photo.path)}}),_c('div',{staticClass:"product-photo__actions d-flex align-items-center justify-content-end"},[_c('button',{staticClass:"button-icon button-icon--ml-10 button-icon--bg-white",attrs:{"disabled":_vm.isStoring},on:{"click":function($event){return _vm.deleteObject(photo)}}},[_c('icon',{attrs:{"icon":"delete"}})],1)])])])}),0):(
      _vm.localProduct[_vm.photoType] && Object.keys(_vm.localProduct[_vm.photoType]).length
    )?_c('div',{staticClass:"row"},[_c('div',{class:'col-' + _vm.column},[_c('div',{staticClass:"product-photo"},[_c('img',{staticClass:"product-photo__img",attrs:{"src":_vm.getApiPath(_vm.localProduct[_vm.photoType].path)}}),_c('div',{staticClass:"product-photo__actions d-flex align-items-center justify-content-end"},[_c('button',{staticClass:"button-icon button-icon--ml-10 button-icon--bg-white",attrs:{"disabled":_vm.isStoring},on:{"click":function($event){return _vm.deleteObject(_vm.localProduct[_vm.photoType])}}},[_c('icon',{attrs:{"icon":"delete"}})],1)])])])]):_vm._e(),_c('modal',{attrs:{"show":_vm.showModal,"size":"md"},on:{"close":function($event){_vm.showModal = !_vm.showModal}}},[_c('div',{staticClass:"notice-modal__header--title",attrs:{"slot":"header"},slot:"header"},[_vm._v(" Usuwanie zdjecia produktu ")]),_c('div',{attrs:{"slot":"body"},slot:"body"},[_vm._v(" Czy na pewno chcesz usunąć powiązanie zdjęcia z produktem? "),_c('div',{staticClass:"product-photo mt-4"},[_c('img',{attrs:{"src":_vm.getApiPath(_vm.objectToDelete.path)}})])]),_c('div',{staticClass:"d-flex align-items-center",attrs:{"slot":"footer"},slot:"footer"},[_c('button',{staticClass:"button button--small button--grey",on:{"click":function($event){_vm.showModal = false}}},[_vm._v(" Nie ")]),_c('button',{staticClass:"button button--small button--ml-10",on:{"click":function($event){return _vm.confirmdeleteObject()}}},[_vm._v(" Tak ")])])])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }